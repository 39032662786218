import styled from "styled-components";

const RFICodeStyled = styled.ul`
  padding: 1.5rem;
  height: auto;

  li {
    font-size: 1.125rem;

    span {
      font-weight: 700;
    }
  }
`;

export default function RFICode(props) {
  const { river_function_name, river_function_desc_txt } = props.data;
  return (
    <RFICodeStyled className='collapsible__content'>
      <li>
        <span>Name:</span> {river_function_name}
      </li>
      <li>
        <span>Description:</span> {river_function_desc_txt}
      </li>
    </RFICodeStyled>
  );
}

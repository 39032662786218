import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { setActivePage } from "../slices/navigationSlice";
import ORNLLogo from "./SVGs/ORNLLogo";
import Nav from "./Header/Nav";

const HeaderStyled = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  box-shadow: ${(props) => props.theme.shadow.primary};
  box-sizing: border-box;

  @media screen and (max-width: ${(props) => props.theme.breakpoint.lg}) {
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 0px 20px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoint.sm}) {
  }

  .ornl-logo {
    margin-left: 12px;
  }
`;

const Header = () => {
  const dispatch = useDispatch();
  return (
    <HeaderStyled className='header'>
      <NavLink to='/' onClick={() => dispatch(setActivePage("/"))}>
        <ORNLLogo />
      </NavLink>
      <Nav />
    </HeaderStyled>
  );
};

export default Header;

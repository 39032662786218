import React from "react";
import "../../config.js";
import { CgRadioCheck } from "react-icons/cg";
import { MdCheckCircle } from "react-icons/md";
import { getAltClassName } from "../Utilities/Util.js";
import QuestionDetailJustification from "../QuestionDetailJustification.js";
import {
  barChartBlue,
  barChartOrange,
  barChartGreen,
  barChartSilver,
} from "../colors";

const FinalReportQuestionSection = ({ sectionData, questionList }) => {
  const questionsUnderThisSection = questionList.filter((questionItem) => {
    return (
      questionItem.section_id === sectionData.section_id &&
      questionItem.parent_id === null
    );
  });

  return (
    <>
      <tr>
        <td colSpan='2' style={{ marginTop: "40px" }}>
          &nbsp;
          <br />
          <h4 className='section-heading'>{sectionData.section_name}</h4>
        </td>
        <td
          title='Yes'
          style={{ backgroundColor: barChartBlue, textAlign: "center" }}
        >
          Yes
        </td>
        <td
          title='No'
          style={{ backgroundColor: barChartOrange, textAlign: "center" }}
        >
          No
        </td>
        <td
          title='Uncertain'
          style={{ backgroundColor: barChartGreen, textAlign: "center" }}
        >
          Unc
        </td>
        <td
          title='Not Applicable'
          style={{
            backgroundColor: barChartSilver,
            textAlign: "center",
            color: "black",
          }}
        >
          NA
        </td>
      </tr>

      {questionsUnderThisSection.map((question, index) => (
        <tr
          key={index}
          className={
            question.answer_data.is_visible === 0
              ? "not-asked-question " +
                getAltClassName(question.question_display_id)
              : getAltClassName(question.question_display_id)
          }
          style={{ border: "1px dotted silver", padding: "4px" }}
        >
          <td style={{ verticalAlign: "top" }}>
            {question.section_group_txt !== "S" ? "Q" : "S"}
            {question.question_display_id}:{" "}
          </td>
          <td>
            {question.question_name}
            {/* {question.option_group_id} */}
            <QuestionDetailJustification
              questionAnswerData={question}
              questionAnswerList={questionList}
            ></QuestionDetailJustification>
          </td>

          {question.answer_data.is_visible === 0 ? (
            <td
              colSpan='4'
              style={{ whiteSpace: "nowrap", color: barChartOrange }}
            >
              Not Asked
            </td>
          ) : (
            <>
              <td title='Yes' style={{ verticalAlign: "top" }}>
                {question.answer_data.choice_id ===
                global.config.questionnaire.option_choice_id.yes ? (
                  <MdCheckCircle
                    style={{ color: barChartBlue, fontSize: "1.0rem" }}
                  />
                ) : (
                  <CgRadioCheck
                    style={{ color: barChartBlue, fontSize: "1.0rem" }}
                  />
                )}
              </td>
              <td title='No' style={{ verticalAlign: "top" }}>
                {question.answer_data.choice_id ===
                global.config.questionnaire.option_choice_id.no ? (
                  <MdCheckCircle
                    style={{ color: barChartOrange, fontSize: "1.0rem" }}
                  />
                ) : (
                  <CgRadioCheck
                    style={{ color: barChartOrange, fontSize: "1.0rem" }}
                  />
                )}
              </td>

              {/* YesNoUncertainNa or YesNoUncertain */}
              {question.option_group_id === 5 ||
              question.option_group_id === 55 ? (
                <td
                  title='Uncertain'
                  style={{ verticalAlign: "top", textAlign: "center" }}
                >
                  {question.answer_data.choice_id ===
                  global.config.questionnaire.option_choice_id.uncertain ? (
                    <MdCheckCircle
                      style={{ color: barChartGreen, fontSize: "1.0rem" }}
                    />
                  ) : (
                    <CgRadioCheck
                      style={{ color: barChartGreen, fontSize: "1.0rem" }}
                    />
                  )}
                </td>
              ) : (
                <td></td>
              )}

              {/* YesNoUncertainNa */}
              {question.option_group_id === 5 ? (
                <td title='Not Applicable' style={{ verticalAlign: "top" }}>
                  {question.answer_data.choice_id ===
                  global.config.questionnaire.option_choice_id
                    .not_applicable ? (
                    <MdCheckCircle
                      style={{ color: barChartOrange, fontSize: "1.0rem" }}
                    />
                  ) : (
                    <CgRadioCheck
                      style={{ color: barChartOrange, fontSize: "1.0rem" }}
                    />
                  )}
                </td>
              ) : (
                <td></td>
              )}
            </>
          )}
        </tr>
      ))}
    </>
  );
};

export default FinalReportQuestionSection;

import { useState } from "react";

const CheckboxInput = ({
  questionDataParam,
  checkboxOption,
  userSelectionIsCheck,
  onCheckboxClick,
}) => {
  const [isChecked, setIsCheck] = useState(userSelectionIsCheck);

  const handleCheckboxClick = (e) => {
    if (onCheckboxClick !== undefined) {
      onCheckboxClick(
        checkboxOption.option_choice_id,
        "",
        e.target.checked,
        checkboxOption.option_choice_name
      );
    }
    setIsCheck(e.target.checked);
  };

  //TODO make it so you can check on wording or box

  return (
    <>
      <div className='input-group-text'>
        <input
          id={
            questionDataParam.question_id +
            "_" +
            checkboxOption.option_choice_id
          }
          type='checkbox'
          checked={isChecked}
          onChange={(e) => handleCheckboxClick(e)}
        />
      </div>
      <label
        htmlFor={
          questionDataParam.question_id + "_" + checkboxOption.option_choice_id
        }
        className='form-control'
      >
        {checkboxOption.option_choice_name}
      </label>
    </>
  );
};
export default CheckboxInput;

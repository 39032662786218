import QuestionRadioType from "./QuestionRadioType";
import QuestionCheckboxType from "./QuestionCheckboxType";
import QuestionTextboxType from "./QuestionTextboxType";
import QuestionTextAreaType from "./QuestionTextAreaType";

import { useCallback } from "react";
import QuestionSubContents from "./QuestionSubContents";
import QuestionNameText from "./QuestionNameText";

const Question = ({
  sectionData,
  questionListParam,
  questionData,
  onAnswerUpdateInQuestion,
}) => {
  const subQuestionList = questionListParam.filter((subQuestion) => {
    return subQuestion.parent_id === questionData.question_id;
  });

  // Radio Type Event
  const onRadioInputClick = useCallback(
    (choiceId, userComments) => {
      let thisAnswerData = {
        question_id: questionData.question_id,
        is_visible: 1,
        choice_id: choiceId,
        choice_comments: userComments,
        option_choices: null,
        answer_datetime: new Date().toLocaleString(),
      };
      questionData.answer_data = thisAnswerData;

      if (onAnswerUpdateInQuestion !== undefined)
        onAnswerUpdateInQuestion(questionData);
    },
    [questionData, onAnswerUpdateInQuestion]
  );

  // Checkbox Type Event
  const onCheckboxClick = useCallback(
    (choiceAnswerList) => {
      let thisAnswerData = {
        question_id: questionData.question_id,
        is_visible: 1,
        choice_id: null,
        choice_comments: null,
        option_choices: choiceAnswerList,
        answer_datetime: new Date().toLocaleString(),
      };
      questionData.answer_data = thisAnswerData;

      if (onAnswerUpdateInQuestion !== undefined)
        onAnswerUpdateInQuestion(questionData);
    },
    [questionData, onAnswerUpdateInQuestion]
  );

  // Text Event
  const onTextboxInputChange = useCallback(
    (textInput) => {
      let thisAnswerData = {
        question_id: questionData.question_id,
        is_visible: 1,
        choice_id: null,
        choice_comments: textInput,
        option_choices: null,
        answer_datetime: new Date().toLocaleString(),
      };

      questionData.answer_data = thisAnswerData;

      //setAnswerData(thisAnswerData);
      if (onAnswerUpdateInQuestion !== undefined)
        onAnswerUpdateInQuestion(questionData);
      else
        console.log(
          "onAnswerUpdateInQuestion undefined inside onTextboxInputChange"
        );
    },
    [questionData, onAnswerUpdateInQuestion]
  );

  return (
    (questionData.answer_data.is_visible == null ||
      questionData.answer_data.is_visible === 1 ||
      questionData.answer_data.is_visible === 999 ||
      questionData.answer_data.is_visible === undefined) && (
      <div
        className={
          questionData.parent_id === null
            ? "question group-in-print"
            : "subQuestion"
        }
        id={"qid" + questionData.question_id}
      >
        {/* {QuestionNameText(questionData.answer_data.is_visible )} */}

        <QuestionNameText questionData={questionData}></QuestionNameText>

        {questionData.input_type_name === "radio" && (
          <QuestionRadioType
            questionDataParam={questionData}
            onRadioInputClick={onRadioInputClick}
          />
        )}

        {questionData.input_type_name === "checkbox" && (
          <QuestionCheckboxType
            questionDataParam={questionData}
            onCheckboxClick={onCheckboxClick}
          />
        )}

        {questionData.input_type_name === "text" && (
          <QuestionTextboxType
            questionDataParam={questionData}
            onTextboxInputChange={onTextboxInputChange}
          />
        )}

        {questionData.input_type_name === "textarea" && (
          <QuestionTextAreaType
            questionDataParam={questionData}
            onTextareaInputChange={onTextboxInputChange}
          />
        )}

        <QuestionSubContents
          questionId={questionData.question_id}
        ></QuestionSubContents>

        {subQuestionList.map((subQuestion) => (
          <Question
            key={subQuestion.question_id}
            sectionData={sectionData}
            questionListParam={questionListParam}
            questionData={subQuestion}
            onAnswerUpdateInQuestion={onAnswerUpdateInQuestion}
          ></Question>
        ))}
      </div>
    )
  );
};

export default Question;

import React from "react";
import ListOfKeyTopics from "./ListOfKeyTopics.js";

import "../../config.js";

const Topics = ({
  isShowTopicsFunctions
}) => {
  isShowTopicsFunctions =
    isShowTopicsFunctions === null ? true : isShowTopicsFunctions;
  if (!isShowTopicsFunctions) {
    return null
  } else 
  return (
    <><ListOfKeyTopics
      topicCategory={global.config.prioritization.KeyTopicCategory}
    ></ListOfKeyTopics><ListOfKeyTopics
      topicCategory={global.config.prioritization.UncertainTopicCategory}
    ></ListOfKeyTopics></>  
  );
};

export default Topics;

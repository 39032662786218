import React, { useState, useEffect } from "react";
import { CgMoreO, CgMoreVerticalO } from "react-icons/cg";
import QuestionDetail from "./QuestionDetail.js";
import {
  barChartBlue,
  barChartOrange,
  barChartGreen,
  barChartSilver,
} from "./colors";

import RiverFunctionPercentageBar from "./Utilities/RiverFunctionPercentageBar.js";

const RiverFunctionChartCategoryDetail = ({
  rfc,
  riverFunction,
  answerJson,
  isShowDetail,
}) => {
  const [isShowDetailOption, setIsShowDetailOption] = useState(isShowDetail);

  useEffect(() => {
    setIsShowDetailOption(isShowDetail);
  }, [isShowDetail]);

  // this is the list of questions for this River Function
  const questionListUnderThisRiverFunction = answerJson.filter(function (
    answerData
  ) {
    return this.indexOf(answerData.question_id) >= 0;
  },
  riverFunction.question_list); // riverFunction.question_list: [1, 2, 3, 4, 5, etc..]

  const categoryNameClassName = isShowDetailOption
    ? "rfCategory-selected-" + rfc.river_function_category_code
    : "";

  return (
    <>
      <tr
        className="expandCollapse"
        onClick={(e) => setIsShowDetailOption(!isShowDetailOption)}
      >
        <td></td>
        {/* this is River Function */}
        <td
          className={
            "river-function-item expandCollapse " + categoryNameClassName
          }
        >
          <span title={riverFunction.river_function_desc_txt}>
            {riverFunction.river_function_name}&nbsp;(
            {riverFunction.river_function_code})
          </span>
        </td>
        <td
          className={categoryNameClassName}
          title="Show/Hide Questions/Answers Lists under this River Function"
        >
          {/* This is an icon.  Depending on the NotAnsweredCount and Expanded/Collapsed, it shows different icon (style) */}
          {!isShowDetailOption ? (
            riverFunction.not_answered_count > 0 ? (
              <CgMoreO color="red" title="Some questions Have Not Answered" />
            ) : (
              <CgMoreO />
            )
          ) : riverFunction.not_answered_count > 0 ? (
            <CgMoreVerticalO
              color="red"
              title="Some questions Have Not Answered"
            />
          ) : (
            <CgMoreVerticalO />
          )}
        </td>
        <td>
          <RiverFunctionPercentageBar
            key={rfc.river_function_category_id}
            riverFunction={riverFunction}
          ></RiverFunctionPercentageBar>
        </td>
      </tr>
      {/* This section is to display questions under River Function */}
      {isShowDetailOption && (
        <tr>
          <td colSpan="4" align="right">
            <table
              className="river-function-item-question-table"
              style={{ marginBottom: "20px", marginLeft: "20%" }}
            >
              <thead>
                <tr>
                  <td
                    colSpan="2"
                    rowSpan="2"
                    style={{ paddingRight: "20px", padding: "2px" }}
                    // className={'rfc-code-' + rfc.river_function_category_code}
                  >
                    Total {questionListUnderThisRiverFunction.length} questions
                    under "{riverFunction.river_function_name} (
                    {riverFunction.river_function_code})" <br></br>(
                    {riverFunction.river_function_desc_txt})
                  </td>
                  <td
                    title="Yes"
                    style={{
                      backgroundColor: barChartBlue,
                      textAlign: "center",
                    }}
                  >
                    Yes
                  </td>
                  <td
                    title="No"
                    style={{
                      backgroundColor: barChartOrange,
                      textAlign: "center",
                    }}
                  >
                    No
                  </td>
                  <td
                    title="Uncertain"
                    style={{
                      backgroundColor: barChartGreen,
                      textAlign: "center",
                    }}
                  >
                    Unc
                  </td>
                  <td
                    title="Not Applicable"
                    style={{
                      backgroundColor: barChartSilver,
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    NA
                  </td>
                </tr>
                <tr>
                  <td
                    title="Yes"
                    style={{
                      backgroundColor: barChartBlue,
                      textAlign: "center",
                    }}
                  >
                    {riverFunction.yes_count}
                  </td>
                  <td
                    title="No"
                    style={{
                      backgroundColor: barChartOrange,
                      textAlign: "center",
                    }}
                  >
                    {riverFunction.no_count}
                  </td>
                  <td
                    title="Uncertain"
                    style={{
                      backgroundColor: barChartGreen,
                      textAlign: "center",
                    }}
                  >
                    {riverFunction.unc_count}
                  </td>
                  <td
                    title="Not Applicable"
                    style={{
                      backgroundColor: barChartSilver,
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    {riverFunction.na_count}
                  </td>
                </tr>
              </thead>
              <tbody>
                {questionListUnderThisRiverFunction.map((questionAnswer, i) => (
                  <QuestionDetail
                    key={i}
                    iRowIndex={i}
                    questionAnswer={questionAnswer}
                    questionAnswerList={answerJson}
                  />
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
};

export default RiverFunctionChartCategoryDetail;

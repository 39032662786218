import React, { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { setActivePage } from "../slices/navigationSlice";

const InPageNavStyled = styled.ul`
  display: flex;
  align-self: flex-start;
  margin-top: 1rem;

  a {
    color: ${(props) => props.theme.color.primary};
    text-decoration: underline;
    margin-right: 0.25rem;

    &:hover {
      text-decoration: none;
    }
  }

  span {
    margin-right: 0.25rem;
  }

  .disabled {
    text-decoration: none;
    color: gray;
  }

  .direction {
    margin-right: 0.25rem;

    &:last-child {
      display: none;
    }
  }
`;

export default function InPageNav(props) {
  const { navItems } = props;
  const [currentPath, setCurrentPath] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, []);
  return (
    <InPageNavStyled className="inpage-nav">
      {navItems.map((item, index) => (
        <Fragment key={index}>
          <li>
            {item.to === currentPath ? (
              <span>{item.text}</span>
            ) : (
              <NavLink
                to={item.to}
                onClick={() => dispatch(setActivePage(item.to))}
              >
                {item.text}
              </NavLink>
            )}
          </li>
          <span className="direction">{">"}</span>
        </Fragment>
      ))}
    </InPageNavStyled>
  );
}

import React from "react";

export const UserGuide = () => {
  const height = window.innerHeight - 228;
  const pixels = `${height}px`;
  return (
    <div className="row justify-content-center">
      <iframe
        src="https://view.officeapps.live.com/op/embed.aspx?src=https://rfiq.ornl.gov/documents/RFI_Questionnaire_V5_UserGuide.docx"
        width="1366px"
        height={pixels}
        frameBorder="0"
        title="user guide"
      >
        This is an embedded{" "}
        <a target="_blank" rel="noreferrer" href="https://office.com">
          Microsoft Office
        </a>{" "}
        document, powered by{" "}
        <a target="_blank" rel="noreferrer" href="https://office.com/webapps">
          Office Online
        </a>
        .
      </iframe>
    </div>
  );
};

export default UserGuide;

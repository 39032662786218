import styled from "styled-components";

const NextModuleStyled = styled.section`
  display: flex;
  flex-direction: column;
  margin: 16px;
  padding: 16px;
  max-width: 320px;
  height: 330px;
  border: 3px solid ${(props) => props.$moduleColor};
  border-radius: 16px;
  background-color: #f2f2f2;
  color: #000;
  box-sizing: border-box;

  &:hover {
    position: relative;
    background-color: #fff;
    box-shadow: ${(props) => props.theme.shadow.primary};
    top: -8px;
  }

  .title-marker {
    position: relative;
    display: flex;
    align-items: center;
    width: 112px;
    height: 36px;
    margin-left: -32px;
    padding-left: 16px;
    background-color: ${(props) => props.$moduleColor};
    color: #fff;

    &:after {
      content: " ";
      position: absolute;
      top: 0;
      right: -18px;
      width: 0;
      height: 0;
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent;
      border-left: 18px solid ${(props) => props.$moduleColor};
    }
  }

  .icon {
    margin-top: 1rem;
    align-self: center;

    path {
      stroke: none !important;
    }

    g {
      fill: ${(props) => props.$moduleColor};
    }
  }

  .copy {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 2rem;

    strong {
      margin: 0 0.375rem;
      color: ${(props) => props.$moduleColor};
    }
  }
`;

export default function NextModule(props) {
  const { moduleColor, icon, text, title } = props;
  return (
    <NextModuleStyled $moduleColor={moduleColor}>
      <span className='title-marker'>{title}</span>
      {icon}
      {text}
    </NextModuleStyled>
  );
}

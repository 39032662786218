import { useEffect, useState } from "react";
import styled from "styled-components";
import Collapsible from "./Collapsible";

import rfiCodeData from "./Data/RiverFunctionWithQuestionArray.json";
import RFICode from "./RFICode";

const RFICategoryStyled = styled.section`
  display: flex;
  flex-direction: column;

  div {
    display: grid;
    grid-template: auto 1fr / 1fr 2fr;
    row-gap: 0.25rem;
    margin-top: 1.125rem;
    font-size: 1.125rem;
    line-height: 1.25rem;

    .grid-item {
      padding: 0 33px;
      font-size: 1.125rem !important;
      line-height: 1.5rem;
    }

    .column-header {
      font-family: ${(props) => props.theme.font[0]};
      font-weight: 700;
    }
  }

  .rfi-code__title {
    margin: 0 0 1rem 1rem;
    font-size: 1.125rem;
    font-weight: 700;
  }

  .rfi-code__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-gap: 8px;
    align-items: flex-start;
    margin-bottom: 1.25rem;
    padding: 0 1rem;

    @media screen and (max-width: ${(props) => props.theme.breakpoint.md}) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: ${(props) => props.theme.breakpoint.sm}) {
      grid-template-columns: 1fr;
    }

    .collapsible {
      margin-bottom: 0;
      width: 100%;
    }
  }
`;

export default function RFICategory(props) {
  const { bgColor, borderColor, categoryId, definition, impact } = props;
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fd = rfiCodeData.filter((el) => {
      return el.river_function_category_id === categoryId;
    });
    setFilteredData(fd);
  }, [categoryId]);
  return (
    <RFICategoryStyled className='collapsible__content rfi__grid'>
      <div>
        <span className='grid-item column-header'>Definition</span>
        <span className='grid-item column-header'>
          Importance for Understanding Hydropower Impacts
        </span>
        <span className='grid-item copy'>{definition}</span>
        <span className='grid-item copy'>{impact}</span>
      </div>
      <h5 className='rfi-code__title'>RFI Category Code Details</h5>
      <section className='rfi-code__wrapper'>
        {filteredData && filteredData.length > 0
          ? filteredData.map((rfiCode, index) => {
              return (
                <Collapsible
                  bgColor={bgColor}
                  borderColor={borderColor}
                  centerTitle={false}
                  title={rfiCode.river_function_code}
                  content={null}
                  key={index}
                >
                  <RFICode data={rfiCode} />
                </Collapsible>
              );
            })
          : null}
      </section>
    </RFICategoryStyled>
  );
}

import { useState } from "react";
import QuestionOptionData from "./Data/QuestionOptionData.json";
import regionLookup from "./Data/RegionLookup.json";

const QuestionRadioType = ({ questionDataParam, onRadioInputClick }) => {
  const [choiceId, setChoiceId] = useState(
    questionDataParam.answer_data.choice_id
  );
  const [userComments, setUserComments] = useState(
    questionDataParam.answer_data.choice_comments
  );
  const [userCommentsTmp, setUserCommentsTmp] = useState(
    questionDataParam.answer_data.choice_comments
  );

  let questionOption = QuestionOptionData.filter((option) => {
    return option.option_group_id === questionDataParam.option_group_id;
  });

  // Filter for region
  const regionAnswer = localStorage.getItem("AnswerToQuestion_40");
  if (
    questionDataParam.question_short_name === "State this project is located" &&
    regionAnswer
  ) {
    questionOption = questionOption.filter((row) => {
      const regionNumber = JSON.parse(regionAnswer).choice_id;
      const stateArray = regionLookup[regionNumber];
      return stateArray.includes(row.option_choice_name);
    });
  }

  const radioChangeHandler = (option_choice_id, is_addition_input_ind, e) => {
    setChoiceId(option_choice_id);

    if (is_addition_input_ind !== 1) {
      setUserCommentsTmp(userComments);
      setUserComments("");
      onRadioInputClick(option_choice_id, "");
    } else {
      onRadioInputClick(option_choice_id, userCommentsTmp);
      setUserComments(userCommentsTmp);
    }
  };

  const userCommentsBlurHandler = (option_choice_id, choiceName, e) => {
    let isOtherPleaseSpecify =
      choiceName.toLowerCase().indexOf("(") >= 0 &&
      choiceName.indexOf("specify") >= 0;
    setUserComments(e.target.value);

    if (isOtherPleaseSpecify) {
      onRadioInputClick(option_choice_id, e.target.value);
      setUserCommentsTmp(e.target.value);
      setUserComments(e.target.value);
    }
    onRadioInputClick(option_choice_id, e.target.value);
  };
  return (
    <>
      {questionOption.map((option) => {
        let isOtherPleaseSpecify =
          option.is_addition_input_ind === 1 &&
          choiceId === option.option_choice_id;
        const title =
          option.option_choice_name === "Uncertain"
            ? "Please explain your uncertainty in the notes box below"
            : "";
        return (
          <div key={option.option_choice_id} className="input-group">
            <div className="input-group-text">
              <input
                type="radio"
                id={
                  questionDataParam.question_id + "_" + option.option_choice_id
                }
                value={option.option_choice_id ? option.option_choice_id : 0}
                checked={choiceId === option.option_choice_id}
                name={"Q" + questionDataParam.question_id}
                onChange={(e) =>
                  radioChangeHandler(
                    option.option_choice_id,
                    option.is_addition_input_ind,
                    e
                  )
                }
              />
            </div>
            <label
              htmlFor={
                questionDataParam.question_id + "_" + option.option_choice_id
              }
              title={title}
              className="form-control"
            >
              {option.option_choice_name}
            </label>
            <input
              type="textbox"
              value={userComments ? userComments : ""}
              width="20px"
              hidden={!isOtherPleaseSpecify}
              placeholder="Enter your answer"
              title={option.option_choice_name}
              onChange={(e) => setUserComments(e.target.value)}
              onBlur={(e) =>
                userCommentsBlurHandler(
                  option.option_choice_id,
                  option.option_choice_name,
                  e
                )
              }
              className="form-control"
            />
          </div>
        );
      })}
    </>
  );
};

export default QuestionRadioType;

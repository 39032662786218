export default function PDFIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='pdf-icon'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21 6.75V21C21 21.7956 20.6839 22.5587 20.1213 23.1213C19.5587 23.6839 18.7956 24 18 24H16.5V22.5H18C18.3978 22.5 18.7794 22.342 19.0607 22.0607C19.342 21.7794 19.5 21.3978 19.5 21V6.75H16.5C15.9033 6.75 15.331 6.51295 14.909 6.09099C14.4871 5.66903 14.25 5.09674 14.25 4.5V1.5H6C5.60218 1.5 5.22064 1.65804 4.93934 1.93934C4.65804 2.22064 4.5 2.60218 4.5 3V16.5H3V3C3 2.20435 3.31607 1.44129 3.87868 0.87868C4.44129 0.316071 5.20435 0 6 0L14.25 0L21 6.75ZM2.4 17.775H0V23.7735H1.1865V21.7605H2.391C2.821 21.7605 3.187 21.674 3.489 21.501C3.794 21.326 4.0255 21.089 4.1835 20.79C4.34819 20.4772 4.43125 20.1279 4.425 19.7745C4.425 19.3995 4.346 19.061 4.188 18.759C4.02972 18.4605 3.7906 18.2125 3.498 18.0435C3.198 17.8635 2.832 17.774 2.4 17.775ZM3.2175 19.7745C3.22259 19.9721 3.1788 20.1679 3.09 20.3445C3.01038 20.4984 2.88588 20.6245 2.733 20.706C2.55826 20.792 2.3652 20.8343 2.1705 20.829H1.182V18.72H2.172C2.499 18.72 2.755 18.8105 2.94 18.9915C3.125 19.1745 3.2175 19.4355 3.2175 19.7745ZM5.043 17.775V23.7735H7.233C7.835 23.7735 8.334 23.655 8.73 23.418C9.13073 23.1782 9.44366 22.8159 9.6225 22.3845C9.8185 21.9345 9.9165 21.3925 9.9165 20.7585C9.9165 20.1285 9.8185 19.591 9.6225 19.146C9.4454 18.7198 9.13556 18.3621 8.739 18.126C8.343 17.892 7.8405 17.775 7.2315 17.775H5.043ZM6.2295 18.7425H7.074C7.445 18.7425 7.7495 18.8185 7.9875 18.9705C8.23429 19.1332 8.42083 19.3725 8.5185 19.6515C8.6365 19.9535 8.6955 20.33 8.6955 20.781C8.70015 21.0802 8.66586 21.3787 8.5935 21.669C8.54176 21.8986 8.44152 22.1144 8.2995 22.302C8.16899 22.4702 7.99608 22.6007 7.7985 22.68C7.56698 22.7665 7.32111 22.8083 7.074 22.803H6.2295V18.7425ZM11.844 21.387V23.7735H10.659V17.775H14.481V18.7545H11.844V20.43H14.253V21.387H11.844Z'
        fill='#0067B6'
      />
    </svg>
  );
}

import React from "react";
import "../../config.js";
import { getDateOfCompletion } from "../Utilities/Util.js";

const FinalReportVersionInfo = () => {
  let dateOfCompletion = getDateOfCompletion();
  dateOfCompletion =
    dateOfCompletion === "12/31/1969" ? "Not Completed" : dateOfCompletion;

  return (
    <div style={{ marginBottom: "40px" }}>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td title="This date is based on the Prioritization">
              Date of Completion: {dateOfCompletion}
            </td>
            <td style={{ textAlign: "right" }}>
              Version: {global.config.version}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FinalReportVersionInfo;

import React from "react";
import { GiMountainRoad } from "react-icons/gi";

const PageNotFound = () => {
  document.title = "Page Not Found";

  return (
    <div className="main-content">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div className="content">
              <GiMountainRoad
                style={{ width: "80%", height: "80%", color: "teal" }}
              ></GiMountainRoad>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="content" style={{ height: "500px" }}>
              <h3 className="section-heading">Page Not Found.</h3>
              <p></p>
              <p>Well, that didn't work...</p>
              <p>
                The good news is that there are other ways to find what you're
                looking for. Try the menus above. If you still can't find what
                you need, let us know and we'll try to track it down for you.
              </p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"></div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;

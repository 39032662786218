import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { setActivePage } from "../../slices/navigationSlice";

const SubNavStyled = styled.ul`
  position: absolute;
  top: 42px;
  left: 0;
  list-style: none;
  border: 1px solid ${(props) => props.theme.color.primary};
  background-color: white;
  box-shadow: ${(props) => props.theme.shadow.primary};

  @media screen and (max-width: ${(props) => props.theme.breakpoint.lg}) {
    top: 32px;
  }

  .sub-nav__item {
    padding: 0.375rem 0.75rem;
    transition: all 0.5s;

    &:not(:last-child) {
      border-bottom: 1px solid ${(props) => props.theme.color.primary};
    }

    &:hover {
      position: relative;
      padding-left: 1rem;
      transition: all 0.5s;
    }
  }
`;

export default function SubNav(props) {
  const { navItems } = props;
  const dispatch = useDispatch();
  return (
    <SubNavStyled className='sub-nav'>
      {navItems.map(({ to, text }) => (
        <li className='sub-nav__item' key={text}>
          <NavLink
            to={to}
            title={text}
            key={text}
            onClick={() => dispatch(setActivePage(to))}
          >
            {text}
          </NavLink>
        </li>
      ))}
    </SubNavStyled>
  );
}

import React from "react";

export const Instructions = () => {
  return (
    <div
      className="content-wrapper"
      id="Instructions"
      style={{ pageBreakBefore: "always" }}
    >
      <h2 className="section-heading">Instructions</h2>

      <div className="introduction">
        It will likely take you over an hour to fill out the entire survey, and
        you may exit and return to the RFI Questionnaire as often as needed. The
        tool will autosave your answers within your local browser cache, thereby
        ensuring that you can keep your responses private. But before switching
        devices or browsers, you will need to export your answers to save your
        work.
      </div>

      <div className="introduction">
        As you work through the RFI Questionnaire, you may notice discontinuous
        numbering because some questions will be skipped depending on your
        responses to previous questions. You can track your progress through the
        questions by referring to the percent complete buttons on the
        “Questionnaire Sections” menu located on the upper-left portion of the
        screen. This “Questionnaire Sections” menu will also allow you to jump
        between sections of the survey.
      </div>

      <div className="introduction">
        For most questions, you will be asked to select 1 of 4 possible Answer
        options about the topic’s relevance to the project (“Yes”, “No”,
        “Uncertain” or “Not Applicable”) and up to 3 types of Justification
        options to support the answer (“Project Nexus”, “Meets Agency or Other
        Goal”, and “Existing Information to Answer This Question”). These
        options are defined below. A “Pertinent notes” text box accompanies the
        questions so that you can enter project-specific information about
        existing data sources, species of concern, etc.
      </div>

      <div className="card mb-3">
        <div className="card-header">Answer options (select 1):</div>
        <div className="card-body">
          <ul className="general">
            <li className="general">
              "Yes" means that the topic is relevant to the hydropower project.
            </li>
            <li className="general">
              "No" means that the topic is not relevant to the hydropower
              project.
            </li>
            <li className="general">
              "Uncertain" means that the user does not have the expertise needed
              to answer this question and/or that there may be information gaps
              about the topic in relation to the hydropower project.
            </li>
            <li className="general">
              "Not Applicable" suggests either that the resource does not exist
              at the hydropower project location.
            </li>
          </ul>
        </div>
      </div>

      <div className="card mb-3 group-in-print">
        <div className="card-header">
          Justification options (select up to 3):
        </div>
        <div className="card-body">
          <ul className="general">
            <li className="general">
              "Project Nexus” means that there is a reasonable connection
              between project development or operation and potential effects on
              the resource in question, as defined by 5.9(b)(5) of the 2012 FERC{" "}
              <a
                href="https://www.ferc.gov/sites/default/files/2020-04/AGuidetoUnderstandingandApplyingtheIntegratedLicensingProcessStudyCriteria.pdf"
                target="_blank"
                rel="noreferrer"
                title="A GUIDE TO UNDERSTANDING AND APPLYING THE INTEGRATED LICENSING PROCESS STUDY CRITERIA"
                style={{ textDecoration: "none" }}
              >
                "A Guide to Understanding and Applying the Integrated Licensing
                Process Study Criteria"
              </a>
              .
            </li>
            <li className="general">
              "Meets Agency or Other Goal" means that there is a documented
              connection between the resource in question and an agency’s goal
              and/or the management goals of one or more project stakeholders.
            </li>
            <li className="general">
              "Existing Information to Answer This Question" means that an
              answer of “Yes” or “No” was justified by existing information. (We
              suggest that you add a note to specify the source/type of existing
              information in the “Pertinent notes” text box.)
            </li>
          </ul>
        </div>
      </div>

      <div className="introduction">
        Once you have answered all questions presented to you, you will be able
        to view a normalized Bar Chart summarizing your responses across each of
        the 42 RFIs and 6 categories. Clicking on a bar will enable you to see a
        summary of all related questions and responses for that RFI or category.
        Once you are satisfied with your answers, you can move on to the
        Prioritization portion of the tool.
      </div>

      <div className="introduction">
        In “Prioritization Part 1: Key Topics” you will find a list of those
        RFIs that met a 50% threshold of “Yes” responses. You can click on each
        RFI to see a summary of the related questions and responses as you
        decide which RFIs to retain and which (if any) to discard. In
        “Prioritization Part 2: Uncertain Topics” you will find a list of those
        RFIs that met a 50% threshold of “Uncertain” responses. You can click on
        each RFI to see a summary of the related questions and responses as you
        decide which of these RFIs to retain and which (if any) to discard.
        Please note that since most of the questions are related to more than
        one RFI, you will not see a one-to-one relationship between the total
        number of "Yes" (or “Uncertain”) responses and the total number of RFIs
        that will be flagged as “Key Topics” (or “Uncertain Topics”). If you
        change any of your answers after completing the prioritization process,
        you will receive a warning that you need to re-set your priority topics.
        We encourage you to retain just 3-5 priority topics for a more
        productive discussion.
      </div>

      <div className="introduction">
        After completing the Questionnaire and Prioritization process, you may
        print the Final Reports as PDFs by clicking on the “Print Page” button.
        (Note that you may need to check the Option box for “Background
        Graphics” to see all colors and graphics on the PDF.) You will have the
        sole discretion of deciding how you would like to share your reports
        and/or use your responses in project discussions with other
        stakeholders.
      </div>
    </div>
  );
};

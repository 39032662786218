import React from "react";
import "../../config";
import {
  barChartBlue,
  barChartGreen,
  barChartOrange,
  barChartSilver,
} from "../colors";

const RiverFunctionPercentageBar = ({ riverFunction }) => {
  return <>{GetPercentageBar(riverFunction)}</>;

  // this function is used for both "River Function" and "River Function Category"
  function GetPercentageBar(riverFunction) {
    const k =
      riverFunction.river_function_category_id === null ||
      riverFunction.river_function_category_id === undefined
        ? riverFunction.river_function_code
        : riverFunction.river_function_category_id;

    let content = [];
    if (riverFunction.answered_count === 10000) {
      content.push(
        <table key={k}>
          <tbody>
            <tr>
              <td>na</td>
            </tr>
          </tbody>
        </table>
      );
      return content;
    }

    let percentageYes = parseInt(riverFunction.yes_percentage * 100) + "%";
    let percentageNo = parseInt(riverFunction.no_percentage * 100) + "%";
    let percentageUnc = parseInt(riverFunction.unc_percentage * 100) + "%";
    let percentageNotAnswered =
      parseInt(riverFunction.not_answered_percentage * 100) + "%";

    content.push(
      <table className="river-function-table" style={{ width: "100%" }} key={k}>
        <tbody>
          <tr>
            {/* ************  YES ************ */}
            {riverFunction.yes_percentage > 0 &&
              global.config.chart.chart_type === "image" && (
                <td style={{ width: percentageYes }}>
                  <img
                    src="images/rf_bar_3d_yes.png"
                    className="river-function-bar-image"
                    title={
                      "Yes: " +
                      percentageYes +
                      " " +
                      riverFunction.yes_percentage_desc
                    }
                    alt={"Yes: " + percentageYes}
                  />
                </td>
              )}
            {riverFunction.yes_percentage > 0 &&
              global.config.chart.chart_type === "bgcolor" && (
                <td
                  style={{
                    width: percentageYes,
                    backgroundColor: barChartBlue,
                    textAlign: "center",
                    color: "white",
                  }}
                  title={
                    "Yes: " +
                    percentageYes +
                    " " +
                    riverFunction.yes_percentage_desc
                  }
                >
                  {percentageYes}{" "}
                </td>
              )}

            {/* ************  NO ************ */}
            {riverFunction.no_percentage > 0 &&
              global.config.chart.chart_type === "image" && (
                <td style={{ width: percentageNo }}>
                  <img
                    src="images/rf_bar_3d_no.png"
                    className="river-function-bar-image"
                    title={
                      "No: " +
                      percentageNo +
                      " " +
                      riverFunction.no_percentage_desc
                    }
                    alt={"No: " + percentageNo}
                  />
                </td>
              )}

            {/* No Percentage with background color */}
            {riverFunction.no_percentage > 0 &&
              global.config.chart.chart_type === "bgcolor" && (
                <td
                  style={{
                    width: percentageNo,
                    backgroundColor: barChartOrange,
                    textAlign: "center",
                    color: "white",
                  }}
                  title={
                    "No: " +
                    percentageNo +
                    " " +
                    riverFunction.no_percentage_desc
                  }
                >
                  {percentageNo}{" "}
                </td>
              )}

            {/* ************  Uncertain ************ */}
            {riverFunction.unc_percentage > 0 &&
              global.config.chart.chart_type === "image" && (
                <td style={{ width: percentageUnc }}>
                  <img
                    src="images/rf_bar_3d_uncertain.png"
                    className="river-function-bar-image"
                    title={
                      "Unc: " +
                      percentageUnc +
                      " (" +
                      riverFunction.unc_count +
                      ")"
                    }
                    alt={"Unc: " + percentageUnc}
                  />
                </td>
              )}
            {/* Uncertain Percentage with background color */}
            {riverFunction.unc_percentage > 0 &&
              global.config.chart.chart_type === "bgcolor" && (
                <td
                  style={{
                    width: percentageUnc,
                    backgroundColor: barChartGreen,
                    textAlign: "center",
                    color: "white",
                  }}
                  title={
                    "Unc: " +
                    percentageUnc +
                    " " +
                    riverFunction.unc_percentage_desc
                  }
                >
                  {percentageUnc}{" "}
                </td>
              )}

            {/* ************  Not Answer ************ */}
            {riverFunction.not_answered_percentage > 0 &&
              global.config.chart.chart_type === "image" && (
                <td style={{ width: percentageNotAnswered }}>
                  <img
                    src="images/rf_bar_3d_not_answered.png"
                    className="river-function-bar-image"
                    title={
                      "Not Answered: " +
                      percentageNotAnswered +
                      riverFunction.not_answered_percentage_desc
                    }
                    alt={"Not Answered: " + percentageNotAnswered}
                  />
                </td>
              )}
            {riverFunction.not_answered_percentage > 0 &&
              global.config.chart.chart_type === "bgcolor" && (
                <td
                  style={{
                    width: percentageNotAnswered,
                    backgroundColor: barChartSilver,
                    textAlign: "center",
                    color: "white",
                  }}
                  title={
                    "Not Answered: " +
                    percentageNotAnswered +
                    " " +
                    riverFunction.not_answered_percentage_desc
                  }
                >
                  {percentageNotAnswered}{" "}
                </td>
              )}
          </tr>
        </tbody>
      </table>
    );

    return content;
  }
};

export default RiverFunctionPercentageBar;

import React, { useState } from "react";
import styled from "styled-components";
import { BiSolidDownArrow } from "react-icons/bi";

const CollapsibleStyled = styled.article`
  margin-bottom: 1.25rem;
  width: 100%;
  height: ${(props) => (props.$isCollapsed ? "32px" : "auto")};
  box-shadow: ${(props) =>
    !props.$isCollapsed ? (props) => props.theme.shadow.primary : null};

  & > .collapsible__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: ${(props) =>
      props.$centerTitle ? "center" : "flex-start"};
    padding-left: ${(props) => (props.$centerTitle ? "0" : "24px")};
    width: 100%;
    height: 32px;
    border: 1px solid ${(props) => props.border};
    background-color: ${(props) => props.color};
    font: 500 1.125rem ${(props) => props.theme.font[0]}, sans-serif;
    color: #000;
    transition: 0.5s all;

    &:hover {
      padding-left: ${(props) => (props.$centerTitle ? "0" : "32px")};
      opacity: 0.8;
      transition: 0.5s all;
    }

    svg {
      position: absolute;
      top: 7px;
      right: 12px;
      transform: ${(props) => (props.$isCollapsed ? "rotate(180deg)" : null)};
      transition: 0.25s all;
    }
  }

  & > .collapsible__content {
    overflow: ${(props) => (props.$isCollapsed ? "hidden" : "initial")};
    opacity: ${(props) => (props.$isCollapsed ? "0" : "1")};
    max-height: ${(props) => (props.$isCollapsed ? "0" : "10000px")};
    transition: ${(props) => (props.$isCollapsed ? "all 0s" : "all 0.5s")};
  }
`;

export default function Collapsible(props) {
  const { bgColor, borderColor, centerTitle = false, children, title } = props;
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <CollapsibleStyled
      className='collapsible'
      color={bgColor}
      border={borderColor}
      $centerTitle={centerTitle}
      $isCollapsed={isCollapsed}
    >
      <button
        className='collapsible__button'
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {title}
        <BiSolidDownArrow />
      </button>
      {children}
    </CollapsibleStyled>
  );
}

import React from "react";
import { CgImport } from "react-icons/cg";
import "../config.js";

const ImportAnswers = () => {
  let fileReader;

  //  this is to Load Answer Data (inside Question List) based on json file.
  const LoadJsonData = (jsonDataString) => {
    //const combinedQuestionList = JSON.parse(JSON.stringify(QuestionsListData));
    //debugger;
    // this is to populate LocalStorage.
    if (jsonDataString) {
      try {
        const ansList = JSON.parse(jsonDataString);

        // Remove Any Existing Answers
        for (let key in localStorage) {
          if (
            key.indexOf("AnswerToQuestion_") >= 0 ||
            key.indexOf("Prioritization_") >= 0
          ) {
            localStorage.removeItem(key);
          }
        }

        let fileVersion = "";
        let export_datetime = "";
        ansList.forEach((answerItem) => {
          if (answerItem.question_id === -999) {
            fileVersion =
              answerItem.version !== undefined ? answerItem.version : "";
            export_datetime =
              answerItem.export_datetime !== undefined
                ? answerItem.export_datetime
                : "";
          } else if (
            JSON.stringify(answerItem).match(/prioritization/gi) !== null
          ) {
            localStorage.setItem(
              Object.keys(answerItem),
              JSON.stringify(Object.values(answerItem)[0])
            );
          } else {
            localStorage.setItem(
              "AnswerToQuestion_" + answerItem.question_id,
              JSON.stringify(answerItem)
            );
          }
        });

        if (fileVersion !== "" && fileVersion !== global.config.version) {
          const fileVersionArray = fileVersion.split(".");
          const currentVersionArray = global.config.version.split(".");

          // only if major version is different
          if (fileVersionArray[0] !== currentVersionArray[0]) {
            let msg =
              "The current version is " +
              global.config.version +
              ".  However, the version of your file is " +
              fileVersion;
            msg +=
              export_datetime === ""
                ? "."
                : " (exported on " + export_datetime + ").";
            msg +=
              "  There is a chance that some of your previous answers are not imported properly.";

            alert(msg);
          }
        }

        window.location.href = "/Impacts/Questionnaire";
      } catch (e) {
        alert(
          `The imported file is not valid.  Please check if you selected a proper file. ${e}`
        );
      }
    }
  };

  const handleFileRead = (e) => {
    const content = fileReader.result;
    LoadJsonData(content);
  };

  const handleFileChosen = (file) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };

  const ImportHanlder = (e) => {
    if (
      !window.confirm(
        "You are about to import answers from a previously saved file.  This operation will overwrite your current answers.  Do you want to continue?"
      )
    ) {
      e.preventDefault();
    }
  };

  return (
    <div className="no-print">
      <div className="card">
        <div className="card-header">
          <CgImport></CgImport> Import your answers
        </div>
        <div className="card-body">
          {/* <p className="card-text"></p> */}
          <input
            type="file"
            id="file"
            className="btn btn-secondary"
            accept=".json"
            onChange={(e) => handleFileChosen(e.target.files[0])}
            style={{ display: "none" }}
          />
          <label
            htmlFor="file"
            className="btn btn-outline-primary btn-sm"
            onClick={(e) => ImportHanlder(e)}
          >
            <CgImport></CgImport> Import Answers
          </label>
        </div>
      </div>
    </div>
  );
};

export default ImportAnswers;

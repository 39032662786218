import React from "react";
import QuestionsListData from "../Data/QuestionsData.json";
import { getRevisedQuestionWithAnswers } from "../Utilities/Util.js";
import RiverFunctionNotes from "./RiverFunctionNotes.js";

import "../../config.js";

const ListOfKeyTopics = ({ topicCategory }) => {
  const answerJson = getRevisedQuestionWithAnswers(QuestionsListData);

  const getRiverFunctionPrioritization = (topicCategoryName) => {
    //debugger;
    const priorityTopicCategoryText = localStorage.getItem(
      "Prioritization_" + topicCategoryName
    );

    let newColumns = null;

    if (
      priorityTopicCategoryText !== null &&
      priorityTopicCategoryText !== "" &&
      priorityTopicCategoryText !== "null"
    ) {
      try {
        newColumns = JSON.parse(priorityTopicCategoryText);
        //newColumns = setColumnNameAndDescription(topicCategoryName, newColumns);
      } catch (e) {
        console.log("error:", e);
      }
    }

    return newColumns;
  };
  const topicsForThisCategory = getRiverFunctionPrioritization(topicCategory);
  const retainedTopics =
    topicsForThisCategory === null ? [] : topicsForThisCategory.Retain.items;

  return (
    <div style={{ marginBottom: "40px" }}>
      {topicCategory === global.config.prioritization.KeyTopicCategory && (
        <>
          <h3 className='section-heading'>Key Topics Identified</h3>
          <p>A list of Key river functions you will "retain".</p>
        </>
      )}
      {topicCategory ===
        global.config.prioritization.UncertainTopicCategory && (
        <>
          <h3 className='section-heading'>Uncertain Topics Identified</h3>
          <p>A list of Uncertain river functions you will "retain".</p>
        </>
      )}

      <div>
        {retainedTopics.length === 0 && (
          <div
            style={{
              border: "1px solid red",
              backgroundColor: "yellow",
              color: "red",
              padding: "5px",
              marginBottom: "5px",
              borderRadius: "0.5rem",
            }}
          >
            Not Specified.
          </div>
        )}

        {retainedTopics.map((item, index) => (
          <div key={index}>
            <div
              className='list-group-item d-flex justify-content-between align-items-center'
              title={item.river_function_desc_txt}
              style={{
                userSelect: "none",
                padding: 5,
                marginBottom: "2px",
                fontSize: ".8rem",
                borderRadius: "0.5rem",
                verticalAlign: "middle",
                // backgroundColor: snapshot.isDragging
                //   ? "#263B4A"
                //   : item.bgColor,
                backgroundColor: item.bgColor,
                color: "white",
              }}
              // onClick={(e) => openModal(item.river_function_code)}
            >
              {item.river_function_code}: {item.river_function_name}
              <span
                className='badge bg-light  text-dark rounded-pill'
                title={
                  topicCategory ===
                  global.config.prioritization.KeyTopicCategory
                    ? "Yes percentage"
                    : "Uncertain percentage"
                }
              >
                {topicCategory ===
                  global.config.prioritization.KeyTopicCategory &&
                  parseInt(item.yes_percentage * 100)}
                {topicCategory ===
                  global.config.prioritization.UncertainTopicCategory &&
                  parseInt(item.unc_percentage * 100)}
                %
              </span>
            </div>
            <RiverFunctionNotes
              answerJson={answerJson}
              riverFunction={item}
            ></RiverFunctionNotes>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListOfKeyTopics;

import React from "react";
import styled from "styled-components";
import Demo_Video from "../rfiq_demo.mp4";
import { GrDocumentPdf } from "react-icons/gr";
import PageTitle from "../components/PageTitle";

const HelpStyled = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
`;

export const Help = () => {
  return (
    <HelpStyled className='main-content'>
      <div className='container'>
        <div className='row'>
          <div style={{ marginBottom: "20px" }}>
            <PageTitle title='Help' />
            <p>
              This page provides access to our user guide(s) and two training
              videos: (1) a short (3-minute) overview and demonstration of the
              River Function Indicator (RFI) Questionnaire, and (2) an hour-long
              DOE WPTO R&D Deep Dive Webinar recorded on January 26, 2022 to
              explain the creation of the Environmental Decision Support (EDS)
              Toolkit and a demonstration of the use of the RFI Questionnaire in
              detail.
            </p>
            <PageTitle title='User Guides' />
            <p>Access to our user guide is below. It is in PDF format:</p>
            <p>
              <GrDocumentPdf />{" "}
              <a
                href={`/documents/EDS_Toolkit_UserGuide.pdf`}
                alt='microsoft word user guide'
                title='microsoft user guide'
                target='_blank'
                rel='noreferrer'
              >
                User Guide
              </a>
            </p>
            <PageTitle title='Training Videos' />
            <p style={{ textAlign: "left" }}>
              This page provides access to two training videos: (1) a short
              (3-minute) overview and demonstration of the River Function
              Indicator (RFI) Questionnaire, and (2) an hour-long DOE WPTO R&D
              Deep Dive Webinar recorded on January 26, 2022 to explain the
              creation of the Environmental Decision Support (EDS) Toolkit and
              demonstrate the use of the RFI Questionnaire in detail.
            </p>
            <PageTitle title='Introduction to the RFI Questionnaire' />
            <video style={{ height: "360px", width: "100%" }} controls>
              <source src={Demo_Video} type='video/mp4' />
            </video>
            <PageTitle title='Deep Dive Webinar' />
            <div style={{ height: "360px" }}>
              <iframe
                width='100%'
                height='100%'
                src='https://www.youtube.com/embed/8c29Q_SwTTY'
                title='YouTube video player'
                frameBorder={0}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen={true}
              />
            </div>
          </div>
        </div>
      </div>
    </HelpStyled>
  );
};

export default Help;

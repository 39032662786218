import React from "react";
import { CgRadioCheck } from "react-icons/cg";
import { MdCheckCircle } from "react-icons/md";
import { getAltClassName } from "./Utilities/Util.js";
import QuestionDetailJustification from "./QuestionDetailJustification.js";
import {
  barChartBlue,
  barChartOrange,
  barChartGreen,
  barChartSilver,
} from "./colors";

import "../config.js";

const QuestionDetail = ({ iRowIndex, questionAnswer, questionAnswerList }) => {
  const altClassName = getAltClassName(iRowIndex);

  return (
    <tr
      className={
        questionAnswer.answer_data.is_visible === 0
          ? "not-asked-question " + altClassName
          : altClassName
      }
    >
      {questionAnswer.answer_data.is_visible > 0 ? (
        <>
          <td
            className="question-id"
            title={
              "Q" +
              questionAnswer.tmp_question_id +
              ": " +
              questionAnswer.section_name
            }
          >
            {questionAnswer.tmp_question_id}:
          </td>

          <td
            onClick={() =>
              (window.location.href = "/#qid" + questionAnswer.question_id)
            }
            style={{ cursor: "pointer" }}
            title="Click to view in questionnaire"
          >
            {questionAnswer.question_name}
            {/* {questionAnswer.option_group_id} */}

            {global.config.chart.show_question_justification && (
              <QuestionDetailJustification
                questionAnswerData={questionAnswer}
                questionAnswerList={questionAnswerList}
              />
            )}
          </td>
          <td title="Yes">
            {questionAnswer.answer_data.choice_id ===
            global.config.questionnaire.option_choice_id.yes ? (
              <MdCheckCircle
                style={{ color: barChartBlue, fontSize: "1.0rem" }}
              />
            ) : (
              <CgRadioCheck
                style={{ color: barChartBlue, fontSize: "1.0rem" }}
              />
            )}
          </td>
          <td title="No">
            {questionAnswer.answer_data.choice_id ===
            global.config.questionnaire.option_choice_id.no ? (
              <MdCheckCircle
                style={{ color: barChartOrange, fontSize: "1.0rem" }}
              />
            ) : (
              <CgRadioCheck
                style={{ color: barChartOrange, fontSize: "1.0rem" }}
              />
            )}
          </td>

          {/* YesNoUncertainNa or YesNoUncertain */}
          {questionAnswer.option_group_id === 5 ||
          questionAnswer.option_group_id === 55 ? (
            <td title="Uncertain" style={{ textAlign: "center" }}>
              {questionAnswer.answer_data.choice_id ===
              global.config.questionnaire.option_choice_id.uncertain ? (
                <MdCheckCircle
                  style={{ color: barChartGreen, fontSize: "1.0rem" }}
                />
              ) : (
                <CgRadioCheck
                  style={{ color: barChartGreen, fontSize: "1.0rem" }}
                />
              )}
            </td>
          ) : (
            <td></td>
          )}

          {/* YesNoUncertainNa */}
          {questionAnswer.option_group_id === 5 ? (
            <td title="Not Applicable">
              {questionAnswer.answer_data.choice_id ===
              global.config.questionnaire.option_choice_id.not_applicable ? (
                <MdCheckCircle
                  style={{ color: barChartSilver, fontSize: "1.0rem" }}
                />
              ) : (
                <CgRadioCheck
                  style={{ color: barChartSilver, fontSize: "1.0rem" }}
                />
              )}
            </td>
          ) : (
            <td></td>
          )}
        </>
      ) : (
        <>
          <td className="question-id">{questionAnswer.tmp_question_id}:</td>
          <td title="Question Not Presented">{questionAnswer.question_name}</td>
          <td
            colSpan="4"
            style={{ whiteSpace: "nowrap", color: barChartOrange }}
          >
            Not Asked
          </td>
        </>
      )}
    </tr>
  );
};

export default QuestionDetail;

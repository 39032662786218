import { getUserFullName, getDateOfCompletion } from "../Utilities/Util.js";

const PrintHeader = () => {
  return (
    <div style={{ textAlign: "right", width: "100%" }} className="print-only">
      {getUserFullName()} - {getDateOfCompletion()}
    </div>
  );
};

export default PrintHeader;

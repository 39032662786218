import QuestionsContentListData from "./Data/QuestionContents.json"; // additional contents..

const QuestionSubContents = ({ questionId }) => {
  // additional contents..
  const questionContents = QuestionsContentListData.filter((qc) => {
    return qc.question_id === questionId;
  });

  return (
    <div>
      {questionContents.map((qc) => {
        if (qc.content_type === "image")
          return (
            <div className='subQuestion' key={qc.id}>
              <img
                src={"/images/" + qc.content_data}
                alt='US Regions'
                className='helper-control'
              ></img>
            </div>
          );
        else if (qc.content_type === "p-tag" || qc.content_type === "p") {
          return (
            <p className='subQuestion' key={qc.id}>
              {qc.content_data}
            </p>
          );
        } else if (qc.content_type === "div-tag" || qc.content_type === "div") {
          return (
            <div className='subQuestion' key={qc.id}>
              {qc.content_data}
            </div>
          );
        } else return <span className='subQuestion' key={qc.id}></span>;
      })}
    </div>
  );
};

export default QuestionSubContents;

import React, { useState } from "react";
import SurveySectionListData from "../Data/SurveySectionData.json";
import FinalReportQuestionSection from "./FinalReportQuestionSection.js";
import { getRevisedQuestionWithAnswers } from "../Utilities/Util.js";
import QuestionsListData from "../Data/QuestionsData.json";
import PrintHeader from "./PrintHeader.js";

const FinalReportQuestionMain = ({ isShowFinalReportQuestions }) => {
  isShowFinalReportQuestions =
    isShowFinalReportQuestions === null ? true : isShowFinalReportQuestions;

  const [filterOption, setFilterOption] = useState("All");
  const [currentQuestionList, setCurrentQuestionList] = useState(
    getRevisedQuestionWithAnswers(QuestionsListData)
  );

  if (!isShowFinalReportQuestions) {
    return null;
  }

  const sectionWithoutBackground = SurveySectionListData.filter((section) => {
    return section.section_group_txt !== "B";
  });

  const changeList = (option) => {
    switch (option) {
      case "All":
        setCurrentQuestionList(
          getRevisedQuestionWithAnswers(QuestionsListData)
        );
        break;
      case "Yes":
        setCurrentQuestionList(
          getRevisedQuestionWithAnswers(QuestionsListData).filter(
            (obj) =>
              obj.answer_data.choice_id ===
                global.config.questionnaire.option_choice_id.yes ||
              obj.question_short_name === "Justification" ||
              obj.question_short_name === "Pertinent notes" ||
              obj.question_short_name === "All that apply"
          )
        );
        break;
      case "No":
        setCurrentQuestionList(
          getRevisedQuestionWithAnswers(QuestionsListData).filter(
            (obj) =>
              obj.answer_data.choice_id ===
                global.config.questionnaire.option_choice_id.no ||
              obj.question_short_name === "Justification" ||
              obj.question_short_name === "Pertinent notes" ||
              obj.question_short_name === "All that apply"
          )
        );
        break;
      case "Uncertain":
        setCurrentQuestionList(
          getRevisedQuestionWithAnswers(QuestionsListData).filter(
            (obj) =>
              obj.answer_data.choice_id ===
                global.config.questionnaire.option_choice_id.uncertain ||
              obj.question_short_name === "Justification" ||
              obj.question_short_name === "Pertinent notes" ||
              obj.question_short_name === "All that apply"
          )
        );
        break;
      case "N/A":
        setCurrentQuestionList(
          getRevisedQuestionWithAnswers(QuestionsListData).filter(
            (obj) =>
              obj.answer_data.choice_id ===
                global.config.questionnaire.option_choice_id.not_applicable ||
              obj.question_short_name === "Justification" ||
              obj.question_short_name === "Pertinent notes" ||
              obj.question_short_name === "All that apply"
          )
        );
        break;
      default:
        // Handle default case if needed
        setCurrentQuestionList(
          getRevisedQuestionWithAnswers(QuestionsListData)
        );
        break;
    }

    setFilterOption(option);
  };

  return (
    <div style={{ pageBreakBefore: "always" }}>
      <PrintHeader></PrintHeader>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 className="section-heading">Documentation of All Responses</h3>
        <div className="filter-dropdown">
          <span>Filter Responses: </span>
          <select
            value={filterOption}
            onChange={(e) => changeList(e.target.value)}
          >
            <option value="All">All</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Uncertain">Uncertain</option>
            <option value="N/A">N/A</option>
          </select>
        </div>
      </div>

      <table className="final-report-questions" style={{ width: "100%" }}>
        <tbody>
          {sectionWithoutBackground.map((sectionData, index) => (
            <FinalReportQuestionSection
              key={index}
              sectionData={sectionData}
              questionList={currentQuestionList}
              filterOption={filterOption}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FinalReportQuestionMain;

import { useState, useCallback } from "react";

const CheckboxInputOther = ({
  questionDataParam,
  checkboxOption,
  userSelectionIsCheck,
  userEnteredComments,
  onCheckboxClick,
}) => {
  const [isChecked, setIsCheck] = useState(userSelectionIsCheck);
  const [otherUserInput, setOtherUserInput] = useState(
    userEnteredComments || ""
  );
  const [tmpOtherUserInput, setTmpOtherUserInput] = useState(
    userEnteredComments || ""
  );

  const handleCheckboxClick = (e) => {
    //alert (1)
    setIsCheck(e.target.checked);
    if (e.target.checked) {
      setOtherUserInput(tmpOtherUserInput);
      onCheckboxClick(
        checkboxOption.option_choice_id,
        tmpOtherUserInput,
        e.target.checked,
        checkboxOption.option_choice_name
      );
    } else {
      setTmpOtherUserInput(otherUserInput);
      setOtherUserInput("");
      onCheckboxClick(
        checkboxOption.option_choice_id,
        otherUserInput,
        e.target.checked,
        checkboxOption.option_choice_name
      );
    }
  };

  const handleTextboxInputChange = (txt) => {
    setOtherUserInput(txt);
    setTmpOtherUserInput(txt);
  };

  const handleTextboxIuputBlur = useCallback(
    (e) => {
      onCheckboxClick(
        checkboxOption.option_choice_id,
        e.target.value,
        isChecked,
        checkboxOption.option_choice_name
      );
    },
    [
      onCheckboxClick,
      checkboxOption.option_choice_id,
      isChecked,
      checkboxOption.option_choice_name,
    ]
  );

  return (
    <>
      <div className="input-group-text">
        <input
          id={
            questionDataParam.question_id +
            "_" +
            checkboxOption.option_choice_id
          }
          type="checkbox"
          checked={isChecked}
          value=""
          onChange={(e) => handleCheckboxClick(e)}
        />
      </div>
      {isChecked && (otherUserInput === "" || otherUserInput === null) ? (
        <div className="input-group-text small">
          <small>Please Enter</small>
        </div>
      ) : (
        ""
      )}
      <input
        type="textbox"
        className="form-control warning"
        placeholder={checkboxOption.option_choice_name}
        disabled={!isChecked}
        value={otherUserInput}
        onChange={(e) => handleTextboxInputChange(e.target.value)}
        onBlur={(e) => {
          handleTextboxIuputBlur(e);
        }}
      />
    </>
  );
};

export default CheckboxInputOther;

import React from "react";
import questionList from "./Data/QuestionsData.json";
import { RiDeleteBinFill } from "react-icons/ri";
import { GiAutoRepair } from "react-icons/gi";

const ClearAnswers = () => {
  // this is a backdoor option to fix some data.
  const bShowPertinentVisibility =
    localStorage.getItem("ShowAdminOption") !== null
      ? localStorage.getItem("ShowAdminOption")
      : "";

  const fixPertinentVisibility = () => {
    const pertinentQuestionList = questionList.filter((questionItem) => {
      //localStorage.removeItem("AnswerToQuestion_" + questionItem.question_id);
      return (
        questionItem.parent_id > 0 &&
        questionItem.input_type_name === "textarea" &&
        questionItem.question_name.indexOf("Pertinent") >= 0
      );
    });

    pertinentQuestionList.forEach((questionItem) => {
      let answerFromLocalStorage = localStorage.getItem(
        "AnswerToQuestion_" + questionItem.question_id
      );

      if (answerFromLocalStorage !== null) {
        const ans = JSON.parse(answerFromLocalStorage);

        if (ans.is_visible !== 1) {
          ans.is_visible = 1;
          localStorage.setItem(
            "AnswerToQuestion_" + questionItem.question_id,
            JSON.stringify(ans)
          );
        }
      }
    });

    window.location.href = "/Impacts/Questionnaire";
  };

  //  this is to Load Answer Data (inside Question List) based on json file.
  const clearAnswers = () => {
    if (
      window.confirm(
        "You are about to clear all answers.  Are you sure you want to clear all of your answers?"
      )
    ) {
      for (let key in localStorage) {
        if (
          key.indexOf("AnswerToQuestion_") >= 0 ||
          key.indexOf("Prioritization_") >= 0 ||
          key.indexOf("LatestAnswer_TimeStamp") >= 0 ||
          key.indexOf("UserFullName") >= 0
        ) {
          localStorage.removeItem(key);
        }
      }
      window.location.href = "/Impacts/Questionnaire";
    }
  };

  return (
    <>
      <div className='no-print'>
        <div className='card'>
          <div className='card-header'>
            <RiDeleteBinFill />
            Clear your answers
          </div>
          <div className='card-body'>
            <button
              className='btn btn-outline-danger btn-sm'
              type='button'
              onClick={() => clearAnswers()}
            >
              <RiDeleteBinFill></RiDeleteBinFill> Clear Answers
            </button>
            {bShowPertinentVisibility !== "" && (
              <div
                style={{ marginTop: "10px" }}
                title='This will fix some issue where some question does not show up.'
              >
                <button
                  className='btn btn-outline-secondary btn-sm'
                  type='button'
                  onClick={() => fixPertinentVisibility()}
                >
                  <GiAutoRepair /> Fix Pertinent Visibility
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClearAnswers;

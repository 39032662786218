import React, { useState } from "react";
import styled from "styled-components";
import "../config.js";

const FooterStyled = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 0.875rem;
`;

export default function Footer() {
  const [year] = useState(new Date().getFullYear().toString());
  return (
    <FooterStyled className='footer no-print'>
      <span style={{ color: "#fff", marginRight: "100px" }}>
        &copy;{" "}
        {year +
          " All rights reserved. " +
          global.config.title +
          ", version " +
          global.config.version}
      </span>
      <span>
        <a
          href='https://www.ornl.gov/ornl/contact-us/Security--Privacy-Notice'
          target='_blank'
          rel='noreferrer'
          style={{ color: "white" }}
        >
          Privacy Policy
        </a>
      </span>
    </FooterStyled>
  );
}

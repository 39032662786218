import React from "react";
import FinalReportBackgroundInfo from "./FinalReportBackgroundInfo.js";
import ListOfKeyTopics from "./ListOfKeyTopics.js";
import RiverFunctionSummary from "./RiverFunctionSummary.js";
import PrintHeader from "./PrintHeader.js";
import PrintOption from "../Utilities/PrintOption.js";
import FinalReportVersionInfo from "./FinalReportVersionInfo.js";

const FinalReportSummary = () => {
  document.title = global.config.title + ": Final Report Summary";

  return (
    <div className="main-content">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="content">
              <h1>Final Report (Short)</h1>
              <div>&nbsp;</div>
              <FinalReportVersionInfo />
              <FinalReportBackgroundInfo />
              <div style={{ pageBreakBefore: "always" }}>
                <PrintHeader />
                <ListOfKeyTopics
                  topicCategory={global.config.prioritization.KeyTopicCategory}
                />
                <ListOfKeyTopics
                  topicCategory={
                    global.config.prioritization.UncertainTopicCategory
                  }
                />
              </div>

              <RiverFunctionSummary sortOption="id" />
            </div>
            {/*  End of Content */}
          </div>
          <div className="col-xl-2 col-lg-0 col-md-12 col-sm-12 col-12">
            <div className="sticky-top">
              <PrintOption />
            </div>
          </div>
        </div>

        {/* End of Container */}
      </div>
      {/* End of Main-Content */}
    </div>
  );
};

export default FinalReportSummary;

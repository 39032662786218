import React from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import "../config.js";
import { Instructions } from "./Instructions.js";

const MainContentsQuestionnaireInstruction = () => {
  return (
    <>
      <div className='sticky-top no-print'>
        <div style={{ marginTop: "20px" }}>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            style={{
              width: "130px",
              // boxShadow:
              //   "0 0 10px rgba(0, 0, 0, 0.4), 0 0 30px rgba(0, 0, 0, 0.6)", // Darker shadow
            }}
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasOptions_instructions"
            aria-controls="offcanvasOptions_instructions"
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "550px" }}
            >
              <FiInfo style={{ fill: "white" }} color="white" />
              <p
                style={{
                  margin: "0 0 0 2px",
                  flexBasis: "min-content",
                  color: "white",
                }}
              >
                Instructions
              </p>
            </div>
          </button>
        </div>
      </div>
      <div
        className='offcanvas offcanvas-end instructions'
        tabIndex='-1'
        id='offcanvasOptions_instructions'
        aria-labelledby='offcanvasOptionLabel'
      >
        <div className='offcanvas-header'>
          <h5 className='offcanvas-title' id='offcanvasOptionLabel'>
            <IoIosInformationCircleOutline /> Instructions
          </h5>
          <button
            type='button'
            className='btn-close text-reset'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
          ></button>
        </div>

        <div className='offcanvas-body'>
          <div>
            <Instructions />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContentsQuestionnaireInstruction;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activePage: "",
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setActivePage: (state, action) => {
      const rootPath = "/" + action.payload.replace(/^\/([^/]*).*$/, "$1");

      state.activePage = rootPath;
    },
  },
});

export const { setActivePage } = navigationSlice.actions;

export default navigationSlice.reducer;

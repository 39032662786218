import React from "react";
import "../config.js";

const QuestionDetailJustification = ({
  questionAnswerData,
  questionAnswerList,
}) => {
  if (questionAnswerData.answer_data.is_visible === 0) {
    return <></>;
  }

  let justificationOrAllThatApply = "";
  let justificationTxt = "";
  if (
    questionAnswerData.answer_data.choice_id ===
      global.config.questionnaire.option_choice_id.yes ||
    questionAnswerData.answer_data.choice_id ===
      global.config.questionnaire.option_choice_id.no ||
    questionAnswerData.section_group_txt === "S"
  ) {
    // if (questionAnswerData.section_group_txt === "S")
    //     debugger;

    const justificationQuestionArray = questionAnswerList.filter((answer) => {
      return (
        answer.parent_id === questionAnswerData.question_id &&
        answer.input_type_name === "checkbox"
      );
    });

    let isAnswerVisible = false;

    if (
      justificationQuestionArray !== null &&
      justificationQuestionArray !== undefined &&
      justificationQuestionArray.length > 0
    ) {
      const justificationQuestion = justificationQuestionArray[0];

      justificationOrAllThatApply = justificationQuestion.question_short_name;

      if (
        justificationQuestion.answer_data !== null &&
        justificationQuestion.answer_data !== undefined &&
        justificationQuestion.answer_data.option_choices !== undefined &&
        justificationQuestion.answer_data.option_choices !== null
      ) {
        isAnswerVisible = justificationQuestion.answer_data.is_visible === 1;

        const option_choices = justificationQuestion.answer_data.option_choices;

        if (isAnswerVisible) {
          for (let i = 0; i < option_choices.length; i++) {
            if (option_choices[i].option_choice_id === 1024) {
              justificationTxt +=
                "[Other: " + option_choices[i].comments + "], ";
            } else {
              justificationTxt +=
                "[" + option_choices[i].option_choice_name + "], ";
            }
          }
        }
      }
    }

    if (justificationTxt === "" && isAnswerVisible) {
      justificationTxt = "[Not Selected]";
    } else if (justificationTxt.length > 2) {
      justificationTxt = justificationTxt.substring(
        0,
        justificationTxt.length - 2
      );
    }
  }

  const pertinentNoteQuestion = questionAnswerList.filter((answer) => {
    return (
      answer.parent_id === questionAnswerData.question_id &&
      answer.input_type_name === "textarea" &&
      answer.question_short_name.toLowerCase() === "pertinent notes"
    );
  });

  const pertinentNoteText =
    pertinentNoteQuestion !== null &&
    pertinentNoteQuestion !== undefined &&
    pertinentNoteQuestion.length > 0 &&
    pertinentNoteQuestion[0].answer_data.choice_comments !== null
      ? pertinentNoteQuestion[0].answer_data.choice_comments
      : "";

  const pertinentNoteHeader =
    pertinentNoteText !== ""
      ? pertinentNoteQuestion[0].question_short_name
      : "";

  return justificationTxt !== "" ||
    (pertinentNoteText !== "" && pertinentNoteText !== null) ? (
    <div style={{ paddingLeft: "10px", borderTop: "1px dotted silver" }}>
      <table style={{ width: "100%" }}>
        <tbody>
          {justificationTxt !== "" && (
            <tr>
              <td
                style={{
                  whiteSpace: "nowrap",
                  width: "10%",
                  verticalAlign: "top",
                }}
              >
                {justificationOrAllThatApply}:
              </td>
              <td>{justificationTxt} </td>
            </tr>
          )}
          {pertinentNoteQuestion.length > 0 &&
            pertinentNoteText !== "" &&
            pertinentNoteText !== null && (
              <tr>
                <td
                  style={{
                    whiteSpace: "nowrap",
                    width: "10%",
                    verticalAlign: "top",
                  }}
                >
                  {pertinentNoteHeader}:
                </td>
                <td>[{pertinentNoteText}]</td>
              </tr>
            )}
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
};

export default QuestionDetailJustification;

export default function Chevron() {
  return (
    <svg
      width='12px'
      height='4.88339796px'
      viewBox='0 0 12 4.88339796'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      className='chevron'
    >
      <g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g transform='translate(-843, -29.9799)' fill='#177AC8'>
          <g transform='translate(811, 29.9799)'>
            <polygon
              transform='translate(38, 2.4417) scale(1, -1) translate(-38, -2.4417)'
              points='32 4.88339796 44 4.88339796 38 -4.08720963e-17'
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
}

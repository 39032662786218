import { CgExport } from "react-icons/cg";
import "../config.js";

const ExportAnswers = () => {
  const exportAnswers = () => {
    var AnswerList = [];
    var PrioritizationList = [];
    const version_info = {
      question_id: -999,
      version: global.config.version,
      export_datetime: new Date().toLocaleString(),
    };
    AnswerList = [...AnswerList, version_info];
    PrioritizationList = [...PrioritizationList, version_info];

    for (let key in localStorage) {
      if (key.indexOf("AnswerToQuestion") === 0) {
        if (
          localStorage.getItem(key) !== "null" &&
          key !== "AnswerToQuestion_-999"
        ) {
          const a = JSON.parse(localStorage.getItem(key));

          if (a !== null) AnswerList = [...AnswerList, a];
        }
      }
    }
    for (let key in localStorage) {
      if (key.indexOf("Prioritization") === 0) {
        if (localStorage.getItem(key) !== "null") {
          let a = {};
          try {
            a[`${key}`] = JSON.parse(localStorage.getItem(key));
          } catch (err) {
            a[`${key}`] = localStorage.getItem(key);
          }

          if (a !== null) {
            PrioritizationList = [...PrioritizationList, a];
          }
        }
      }
    }

    var sortedAnswerList = AnswerList.sort(function (obj1, obj2) {
      return obj1.question_id - obj2.question_id;
    });

    const fileName = "river_function_questionnaire_answer";
    const all = [...sortedAnswerList, ...PrioritizationList];
    const json = JSON.stringify(all);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    try {
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {}
  };

  return (
    <>
      <div className="card no-print">
        <div className="card-header">Answers Saved</div>
        <div className="card-body">
          <p className="card-text">
            <small>
              By default, your answers will be saved automatically and will be
              available next time you visit this site. If you intend to retrieve
              your answers from different browsers and/or computers, you can
              export your answers locally and reload them.
            </small>
          </p>
          <button
            type="button"
            className="btn btn-outline-primary btn-sm"
            onClick={() => exportAnswers()}
          >
            <CgExport></CgExport> Export Answers{" "}
          </button>
        </div>
      </div>
    </>
  );
};

export default ExportAnswers;

import React from "react";
import styled from "styled-components";

import PageNavButton from "./PageNavButton.js";

const SequentialPageNavStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 900px;
  margin: 2.5rem 0;
`;

export default function SequentialPageNav(props) {
  const { prev, next } = props;

  return (
    <SequentialPageNavStyled>
      {prev ? <PageNavButton link={prev} /> : null}
      {next ? <PageNavButton link={next} isNext={true} /> : null}
    </SequentialPageNavStyled>
  );
}

import React from "react";
import { GrSettingsOption } from "react-icons/gr";
import MainContentsQuestionnairePageOption from "../MainContentsQuestionnairePageOption";
import "../../config.js";
import Topics from "../FinalReportSummary/Topics.js";
import RiverFunctionCategoryWithQuestionArray from "../Data/RiverFunctionCategoryWithQuestionArray.json";
import Table from "../StudiesTable.js";
import PageTitle from "../PageTitle.js";
import InPageNav from "../InPageNav";
import navItems from "./navItems";
import SequentialPageNav from "../SequentialPageNav/SequentialPageNav.js";

const Studies = () => {
  document.title = global.config.title + ": Studies";

  return (
    <div className='main-content'>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-11 col-lg-12 col-md-12 col-sm-12 col-12'>
            <div className='content'>
              <InPageNav navItems={navItems} />
              <PageTitle title='Explore Studies' />
              <p className='copy'>
                Click on each tab below to explore examples of environmental
                studies from previously licensed US hydropower projects that are
                related to your selected river function indicators (RFIs) of
                concern.
              </p>
              <p style={{ color: "#A03123" }}>
                Note: Data is only available for BB and CF indicators. Other categories
                of indicators coming soon...
              </p>
              <p className='copy'>
                For more detailed information about each study, you can access
                the original Federal Energy Regulatory Commission (FERC)
                licensing records going to the Pacific Northwest National
                Laboratory's (PNNL's) Hydropower eLibrary tool at{" "}
                <a
                  href='https://hydropowerelibrary.pnnl.gov/Documents'
                  target='_blank'
                  rel='noreferrer'
                >
                  https://hydropowerelibrary.pnnl.gov/Documents
                </a>{" "}
                and inputting the listed FERC Docket number (e.g., P-2157) in
                the upper-lefthand “document filter” textbox. Several options
                are available for filtering the resulting list of documents,
                including adding keywords to narrow down your search to the
                studies of interest.
              </p>
              <br></br>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div style={{ width: "22%", marginBottom: "10px" }}>
                  <div className='card' style={{ width: "100%" }}>
                    <div className='card-body'>
                      <Topics isShowTopicsFunctions={true} />
                    </div>
                  </div>
                </div>
                <div style={{ width: "78%", marginBottom: "10px" }}>
                  <div className='card' style={{ width: "100%" }}>
                    <div className='card-body'>
                      <Table />
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  columnGap: "75px",
                  marginTop: "3rem",
                }}
              ></div>
            </div>
            {/*  End of Content */}
          </div>
          <div className='col-xl-1 col-lg-0 col-md-12 col-sm-12 col-12'>
            <div className='sticky-top'>
              {/* Page Option in Canvas for River Function Chart */}
              <div
                style={{ marginTop: "20px", width: "130px" }}
                title='View Topics'
                className='no-print'
              >
                <button
                  type='button'
                  className='btn btn-outline-secondary btn-sm'
                  data-bs-toggle='offcanvas'
                  data-bs-target='#offcanvasOptions_topics'
                  aria-controls='offcanvasOptions_topics'
                  style={{ width: "130px" }}
                >
                  <GrSettingsOption /> Legend
                </button>
              </div>
              <div
                className='offcanvas offcanvas-end topics'
                tabIndex='-1'
                id='offcanvasOptions_topics'
                aria-labelledby='offcanvasOptionLabel'
              >
                <div className='offcanvas-header'>
                  <h5 className='offcanvas-title' id='offcanvasOptionLabel'>
                    Legend
                  </h5>
                  <button
                    type='button'
                    className='btn-close text-reset'
                    data-bs-dismiss='offcanvas'
                    aria-label='Close'
                  ></button>
                </div>
                <div
                  className='card'
                  style={{ width: "665px", margin: "20px" }}
                >
                  <div className='card-body'>
                    <div className='card-header'>Legend</div>
                    <div className='card-body'>
                      {RiverFunctionCategoryWithQuestionArray.slice() // Create a copy of the array to prevent mutating the original
                        .sort((a, b) => {
                          const nameA =
                            a.river_function_category_name.toUpperCase();
                          const nameB =
                            b.river_function_category_name.toUpperCase();
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((rfc, index) => (
                          <div
                            key={index}
                            style={{
                              backgroundColor: rfc.bgColor,
                              margin: "5px",
                              padding: "5px",
                              color: "white",
                              fontSize: ".8rem",
                              borderRadius: "0.5rem",
                            }}
                            title={rfc.definition_txt}
                          >
                            {rfc.river_function_category_code} :{" "}
                            {rfc.river_function_category_name}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <MainContentsQuestionnairePageOption />
            </div>
          </div>

          {/* End of Row */}
        </div>

        {/* End of Container */}
      </div>
      <SequentialPageNav prev='/Next' next='/Next/Mitigations' />
      {/* End of Main-Content */}
    </div>
  );
};

export default Studies;

import React from "react";
import styled from "styled-components";

import MainContentsQuestionnairePageOption from "../MainContentsQuestionnairePageOption";
import { Link } from "react-router-dom";
import "../../config.js";
import NextModule from "./NextModule.js";
import PageTitle from "../PageTitle.js";
import InPageNav from "../InPageNav";
import Certificate from "../SVGs/Certificate.js";
import CheckList from "../SVGs/CheckList.js";
import MagnifyingGlass from "../SVGs/MagnifyingGlass.js";
import navItems from "./navItems";
import SequentialPageNav from "../SequentialPageNav/SequentialPageNav.js";

const NextStyled = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  .section-row {
    display: flex;
    justify-content: space-between;
    width: 85%;
  }

  .intro-copy {
    margin-right: 60px;
  }

  .modules {
    flex-wrap: wrap;
    justify-content: space-around;
  }
`;

export default function Next() {
  document.title = global.config.title + ": What's Next?";

  return (
    <NextStyled className='main-content'>
      <section className='section-row'>
        <div className='intro-copy'>
          <InPageNav navItems={navItems} />
          <PageTitle title="What's Next?" />
          <p>
            This module connects interested parties with information from
            previously licensed US hydropower projects to help them decide
            "What's Next?" in terms of potential environmental impact studies,
            mitigation measures, and permits. Please select which aspect you
            would like to explore further:
          </p>
        </div>
        <div className='sticky-top'>
          <MainContentsQuestionnairePageOption />
          <div
            className='offcanvas offcanvas-end'
            tabIndex='-1'
            id='offcanvasOptions'
            aria-labelledby='offcanvasOptionLabel'
          ></div>
        </div>
      </section>
      <section className='section-row modules'>
        <Link to='/Next/Studies'>
          <NextModule
            icon={<MagnifyingGlass />}
            moduleColor='#2D9679'
            title='Studies'
            text={[
              <p className='copy' key={1}>
                Identify relevant environmental impacts studies for each River
                Function Indicator (RFI) from previous FERC licensed projects
              </p>,
            ]}
          />
        </Link>
        <Link to='/Next/Mitigations'>
          <NextModule
            icon={<CheckList />}
            moduleColor='#017934'
            title='Mitigations'
            text={[
              <p className='copy' key={2}>
                Access relevant mitigation measures for potential environmental
                impacts of hydropower from previous FERC licensed projects
              </p>,
            ]}
          />
        </Link>
        <Link to='/Next/Regulations'>
          <NextModule
            icon={<Certificate />}
            moduleColor='#27B5BB'
            title='Regulations'
            text={[
              <p className='copy' key={3}>
                Connect to relevant information from DOE's Hydropower Regulatory
                and Permitting Information Desktop
                <strong>(RAPID) Toolkit</strong>
              </p>,
            ]}
          />
        </Link>
      </section>
      <SequentialPageNav prev='/FinalReportDetail' next='/Next/Studies' />
    </NextStyled>
  );
}

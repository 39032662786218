import React from "react";
import { GrSettingsOption, GrDownload } from "react-icons/gr";
import ClearAnswers from "./ClearAnswers";
import ExportAnswers from "./ExportAnswers";
import ImportAnswers from "./ImportAnswers";
import "../config.js";
import PrintOption from "./Utilities/PrintOption";

const MainContentsQuestionnairePageOption = () => {
  return (
    <>
      <div className='sticky-top no-print'>
        <div style={{ marginTop: "20px" }}>
          <button
            type='button'
            className='btn btn-outline-secondary btn-sm'
            style={{ display: "flex", width: "130px" }}
            data-bs-toggle='offcanvas'
            data-bs-target='#offcanvasOptions_pageoptions'
            aria-controls='offcanvasOptions_pageoptions'
          >
            <GrDownload
              style={{ alignSelf: "center", width: "25px", marginRight: "4px" }}
            />
            Import/Export Answers
          </button>
          <div style={{ marginTop: "20px" }}>
            <PrintOption />
          </div>
        </div>
      </div>
      <div
        className='offcanvas offcanvas-end'
        tabIndex='-1'
        id='offcanvasOptions_pageoptions'
        aria-labelledby='offcanvasOptionLabel'
      >
        <div className='offcanvas-header'>
          <h5 className='offcanvas-title' id='offcanvasOptionLabel'>
            <GrSettingsOption /> Import/Export Answers
          </h5>
          <button
            type='button'
            className='btn-close text-reset'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
          ></button>
        </div>

        <div className='offcanvas-body'>
          <div>
            <ExportAnswers />
          </div>
          <div style={{ marginTop: "20px" }}>
            <ImportAnswers />
          </div>
          {global.config.allow_clear_answer && (
            <div style={{ marginTop: "20px" }}>
              <ClearAnswers />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MainContentsQuestionnairePageOption;

//import QuestionsListData from './Data/QuestionsData.json'
import PageTitle from "./PageTitle";
import Question from "./Question";

const QuestionSection = ({
  sectionData,
  questionListParam,
  answerListParam,
  onAnswerUpdate,
}) => {
  const questionList = questionListParam;

  const questionsUnderThisSection = questionList.filter((questionItem) => {
    return (
      questionItem.section_id === sectionData.section_id &&
      questionItem.parent_id === null
    );
  });

  const handleAnswerUpdate = (answerData) => {
    onAnswerUpdate(answerData);
  };

  return (
    <div
      className='content-wrapper'
      id={sectionData.section_name.replace(" ", "_")}
      style={{ pageBreakBefore: "always" }}
    >
      {/* <h2 className='section-heading'>{sectionData.section_display_txt}</h2> */}
      <PageTitle title={sectionData.section_display_txt} />

      {sectionData.section_subheading !== "" && (
        <p>{sectionData.section_subheading}</p>
      )}

      {questionsUnderThisSection.map((questionItem) => (
        <Question
          key={questionItem.question_id}
          sectionData={sectionData}
          questionListParam={questionList}
          questionData={questionItem}
          // answerListParam={answerListParam}
          // onQuestionAnswerDependency={handleQuestionAnswerDependency}
          onAnswerUpdateInQuestion={handleAnswerUpdate}
        />
      ))}
    </div>
  );
};

// const getAnswerData = (questionId, answerListParam) => {

//     const answerDataList = answerListParam.filter((ans) => {
//         return ans.question_id === questionId;
//     });

//     if (answerDataList.length > 0)
//         return answerDataList[0];
//     else
//         return null;
// }

export default QuestionSection;

import React from "react";
import styled from "styled-components";

const PageTitleStyled = styled.div`
  align-self: flex-start;
  margin-bottom: 1rem;
  width: 100%;

  h1 {
    margin-top: 2rem;
    padding-bottom: 0.5rem;
    font: 500 2.25rem ${(props) => props.theme.font[0]}, sans-serif;
    color: ${(props) => props.theme.color.body};
    border-bottom: 1px solid #dedede;
  }
`;

export default function PageTitle(props) {
  const { title } = props;
  return (
    <PageTitleStyled>
      <h1>{title}</h1>
    </PageTitleStyled>
  );
}

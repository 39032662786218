import React from "react";
import styled from "styled-components";

import BlueSwoosh from "../images/blue-swoosh.png";

const SwooshStyled = styled.div`
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
`;

export default function Swoosh() {
  return (
    <SwooshStyled className='swoosh'>
      <img src={BlueSwoosh} alt='Blue Swoosh Graphic' />
    </SwooshStyled>
  );
}

export default function MagnifyingGlass() {
  return (
    <svg
      className='icon'
      width='48px'
      height='48px'
      viewBox='0 0 48 48'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-738, -528)' fill='#2D9679'>
          <path d='M780.816907,533.4 C777.211274,529.8 772.403758,528 767.896717,528 C763.089201,528 758.281685,529.8 754.976527,533.4 C749.568074,538.8 748.065728,546.6 750.769952,553.2 C748.967136,555.3 748.967136,555.3 748.967136,555.3 C747.765258,556.5 747.164318,558.3 748.065728,560.1 C739.352112,568.8 739.352112,568.8 739.352112,568.8 C737.549296,570.3 737.549296,573 739.352112,574.8 C740.253521,575.400001 741.15493,576 742.356807,576 C743.258217,576 744.460093,575.400001 745.361502,574.8 C754.075119,565.8 754.075119,565.8 754.075119,565.8 C754.676052,566.1 755.277002,566.4 755.877935,566.4 C757.079818,566.4 757.981227,565.8 758.882635,565.2 C760.685451,563.1 760.685451,563.1 760.685451,563.1 C763.089201,564 765.49295,564.6 767.896717,564.6 C772.403758,564.6 777.211274,562.8 780.816907,559.2 C787.727698,552 787.727698,540.3 780.816907,533.4 Z M750,566 C748,563.846154 748,563.846154 748,563.846154 C750,562 750,562 750,562 C752,563.846154 752,563.846154 752,563.846154 L750,566 Z M778.485442,557.485437 C775.475728,560.495146 771.563118,562 767.650491,562 C763.436888,562 759.524279,560.495146 756.514565,557.485437 C750.495145,551.466019 750.495145,541.533981 756.514565,535.514563 C759.524279,532.504854 763.436888,531 767.650491,531 C771.563118,531 775.475728,532.504854 778.485442,535.514563 C784.504853,541.533981 784.504853,551.466019 778.485442,557.485437 Z'></path>
        </g>
      </g>
    </svg>
  );
}

import QuestionsListData from "../Data/QuestionsData.json";
import {
  getRevisedQuestionWithAnswers,
  getRiverFunctionSummary,
  getRiverFunctionCategorySummary,
} from "../Utilities/Util.js";
import RiverFunctionCategoryWithQuestionArray from "../Data/RiverFunctionCategoryWithQuestionArray.json";
import RiverFunctionWithQuestionArray from "../Data/RiverFunctionWithQuestionArray.json";
import RiverFunctionChartCategory from "../RiverFunctionChartCategory.js";
import PrintHeader from "./PrintHeader.js";
import BarChartPublicationSource from "../BarChartPublicationSource.js";

const RiverFunctionSummary = ({ sortOption }) => {
  const sortOptionForRiverFunctionCategory =
    sortOption === null ? "id" : sortOption; //getDefaultOption('rfc_sort_order');
  const sortOptionForRiverFunction = sortOption === null ? "id" : sortOption; //getDefaultOption('rf_sort_order');
  const isShowDetail = false;
  const isShowQuestionsUnderCategory = false;

  const answerJson = getRevisedQuestionWithAnswers(QuestionsListData);
  const riverFunctionSummary = getRiverFunctionSummary(
    RiverFunctionWithQuestionArray,
    answerJson
  );
  const riverFunctionCategorySummary = getRiverFunctionCategorySummary(
    RiverFunctionCategoryWithQuestionArray,
    answerJson
  );

  const sortRiverFunctionCategories = (riverFunctionCategories, sortBy) => {
    // sort by value
    if (sortBy === "percentage") {
      riverFunctionCategories.sort(function (a, b) {
        if (b.yes_percentage === a.yes_percentage)
          return a.unc_percentage - b.unc_percentage;

        return b.yes_percentage - a.yes_percentage;
      });
    } else if (sortBy === "id") {
      riverFunctionCategories.sort(function (a, b) {
        return a.category_order_index - b.category_order_index;
      });
    }

    return riverFunctionCategories;
  };

  return (
    <div style={{ marginBottom: "40px", pageBreakBefore: "always" }}>
      <PrintHeader />
      <h3 className="section-heading">Results Summary</h3>
      <p>
        The following bar chart is displayed in the order of the{" "}
        {sortOptionForRiverFunctionCategory}.
      </p>
      <table className="river-function">
        <thead>
          <tr>
            <th scope="col" colSpan="3">
              River Functions
            </th>
            <th scope="col">
              Percentage of questions answered "Yes", "No", or "Uncertain"
            </th>
          </tr>
        </thead>
        <tbody>
          {sortRiverFunctionCategories(
            riverFunctionCategorySummary,
            sortOptionForRiverFunctionCategory
          ).map((rfc, i) => (
            <RiverFunctionChartCategory
              key={i}
              rfc={rfc}
              riverFunctionSummary={riverFunctionSummary}
              answerJson={answerJson}
              isShowQuestionsUnderCategory={isShowQuestionsUnderCategory}
              riverFunctionSortOption={sortOptionForRiverFunction}
              isShowDetail={isShowDetail}
              isShowRiverFunctions={true}
              isShowTopicsFunctions={true}
            ></RiverFunctionChartCategory>
          ))}
        </tbody>
      </table>

      <BarChartPublicationSource />
    </div>
  );
};

export default RiverFunctionSummary;

import { useState } from "react";

const QuestionTextAreaType = ({ questionDataParam, onTextareaInputChange }) => {
  const [answerTxt, setAnswerTxt] = useState(
    questionDataParam.answer_data.choice_comments
  );

  // const handleTextboxInputChange = (comments) => {
  //     setAnswerTxt(comments);
  // }

  const handleTextboxBlur = () => {
    if (answerTxt !== null) onTextareaInputChange(answerTxt);
  };

  return (
    <>
      <textarea
        className="textarea-control"
        rows="5"
        id={"textbox_" + questionDataParam.question_id}
        onBlur={() => {
          handleTextboxBlur();
        }}
        onChange={(e) => {
          setAnswerTxt(e.target.value);
        }}
        placeholder="Please enter your note"
        value={answerTxt ? answerTxt : ""}
      />
    </>
  );
};

export default QuestionTextAreaType;

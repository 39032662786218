import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { setActivePage } from "../../slices/navigationSlice";
import { useDispatch } from "react-redux";

const PageNavButtonStyled = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: 2px solid ${(props) => props.theme.color.primary};
  border-radius: 4px;
  background-color: ${(props) => props.theme.color.primary};
  color: #fff;

  &:hover {
    background-color: #fff;
    border: 2px solid ${(props) => props.theme.color.primary};
    color: ${(props) => props.theme.color.primary};
  }
`;

export default function PageNavButton(props) {
  const { isNext, link } = props;
  const dispatch = useDispatch();
  return (
    <PageNavButtonStyled
      to={link}
      onClick={() => dispatch(setActivePage(link))}
    >
      {isNext ? "Next" : "Prev"}
    </PageNavButtonStyled>
  );
}

import "../../config";
import queryString from "query-string";

/* This function Populates Answering Count and Percentage */
export const getRiverFunctionSummary = (
  RiverFunctionWithQuestionArray,
  answerJson
) => {
  RiverFunctionWithQuestionArray.forEach((rf) => {
    let filteredNotAsked = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        answerData.answer_data.is_visible === 0
      );
    }, rf.question_list);

    let filteredYes = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        (answerData.answer_data.is_visible === 1 ||
          answerData.answer_data.is_visible === 999 ||
          answerData.answer_data.is_visible === null) &&
        answerData.answer_data.choice_id ===
          global.config.questionnaire.option_choice_id.yes
      );
    }, rf.question_list);

    let filteredNo = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        (answerData.answer_data.is_visible === 1 ||
          answerData.answer_data.is_visible === 999 ||
          answerData.answer_data.is_visible === null) &&
        answerData.answer_data.choice_id ===
          global.config.questionnaire.option_choice_id.no
      );
    }, rf.question_list);

    let filteredUnc = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        (answerData.answer_data.is_visible === 1 ||
          answerData.answer_data.is_visible === 999 ||
          answerData.answer_data.is_visible === null) &&
        answerData.answer_data.choice_id ===
          global.config.questionnaire.option_choice_id.uncertain
      );
    }, rf.question_list);

    let filteredNa = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        (answerData.answer_data.is_visible === 1 ||
          answerData.answer_data.is_visible === 999 ||
          answerData.answer_data.is_visible === null) &&
        answerData.answer_data.choice_id ===
          global.config.questionnaire.option_choice_id.not_applicable
      );
    }, rf.question_list);

    let filteredNotAnswered = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        (answerData.answer_data.is_visible === 1 ||
          answerData.answer_data.is_visible === 999 ||
          answerData.answer_data.is_visible === null) &&
        answerData.answer_data.choice_id === null
      );
    }, rf.question_list);

    rf.yes_count = filteredYes.length;

    rf.no_count = filteredNo.length;
    rf.na_count = filteredNa.length;
    rf.not_asked_count = filteredNotAsked.length;

    rf.unc_count = filteredUnc.length;

    rf.not_answered_count = filteredNotAnswered.length;
    rf.answered_count =
      filteredYes.length +
      filteredNo.length +
      filteredUnc.length +
      filteredNa.length;

    rf.yes_percentage = rf.yes_count / rf.question_list.length;
    rf.yes_percentage_desc = global.config.chart.test_mode
      ? "(" + rf.yes_count + " of " + rf.question_list.length + ")"
      : "";

    rf.no_percentage =
      (rf.no_count + rf.na_count + rf.not_asked_count) /
      rf.question_list.length;
    rf.no_percentage_desc = global.config.chart.test_mode
      ? "(" +
        rf.no_count +
        "+" +
        rf.na_count +
        "+" +
        rf.not_asked_count +
        ")  / " +
        rf.question_list.length
      : "";

    rf.unc_percentage = rf.unc_count / rf.question_list.length;
    rf.unc_percentage_desc = global.config.chart.test_mode
      ? "(" + rf.unc_count + " of " + rf.question_list.length + ")"
      : "";

    rf.not_answered_percentage =
      rf.not_answered_count / rf.question_list.length;
    rf.not_answered_percentage_desc = global.config.chart.test_mode
      ? "(" + rf.not_answered_count + " of " + rf.question_list.length + ")"
      : "";
    rf.answered_percentage = rf.answered_count / rf.question_list.length;
  });

  return RiverFunctionWithQuestionArray;
};

export const getRiverFunctionCategorySummary = (
  RiverFunctionCategoryWithQuestionArray,
  answerJson
) => {
  RiverFunctionCategoryWithQuestionArray.forEach((rfc) => {
    let filteredAsked = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        (answerData.answer_data.is_visible === 1 ||
          answerData.answer_data.is_visible === 999 ||
          answerData.answer_data.is_visible === null)
      );
    }, rfc.question_list);

    let filteredNotAsked = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        answerData.answer_data.is_visible === 0
      );
    }, rfc.question_list);

    let filteredYes = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        (answerData.answer_data.is_visible === 1 ||
          answerData.answer_data.is_visible === 999 ||
          answerData.answer_data.is_visible === null) &&
        answerData.answer_data.choice_id ===
          global.config.questionnaire.option_choice_id.yes //21
      );
    }, rfc.question_list);

    let filteredNo = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        (answerData.answer_data.is_visible === 1 ||
          answerData.answer_data.is_visible === 999 ||
          answerData.answer_data.is_visible === null) &&
        answerData.answer_data.choice_id ===
          global.config.questionnaire.option_choice_id.no
      );
    }, rfc.question_list);

    let filteredUnc = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        (answerData.answer_data.is_visible === 1 ||
          answerData.answer_data.is_visible === 999 ||
          answerData.answer_data.is_visible === null) &&
        answerData.answer_data.choice_id ===
          global.config.questionnaire.option_choice_id.uncertain
      );
    }, rfc.question_list);

    let filteredNa = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        (answerData.answer_data.is_visible === 1 ||
          answerData.answer_data.is_visible === 999 ||
          answerData.answer_data.is_visible === null) &&
        answerData.answer_data.choice_id ===
          global.config.questionnaire.option_choice_id.not_applicable
      );
    }, rfc.question_list);

    let filteredNotAnswered = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        (answerData.answer_data.is_visible === 1 ||
          answerData.answer_data.is_visible === 999 ||
          answerData.answer_data.is_visible === null) &&
        (answerData.answer_data.choice_id === null ||
          answerData.answer_data.choice_id === undefined)
      );
    }, rfc.question_list);

    rfc.yes_count = filteredYes.length;
    rfc.no_count = filteredNo.length;
    rfc.unc_count = filteredUnc.length;
    rfc.na_count = filteredNa.length;

    rfc.not_asked_count = filteredNotAsked.length;
    rfc.asked_count = filteredAsked.length;

    rfc.answered_count =
      filteredYes.length +
      filteredNo.length +
      filteredUnc.length +
      filteredNa.length;
    rfc.not_answered_count = filteredNotAnswered.length;
    //rfc.not_answered_count = rfc.question_list.length - rfc.answered_count - rfc.not_asked_count; //filteredNotAnswered.length;

    rfc.yes_percentage = rfc.yes_count / rfc.question_list.length;
    rfc.yes_percentage_desc = global.config.chart.test_mode
      ? "(" + rfc.yes_count + " of " + rfc.question_list.length + ")"
      : "";

    rfc.no_percentage =
      (rfc.no_count + rfc.na_count + rfc.not_asked_count) /
      rfc.question_list.length;
    rfc.no_percentage_desc = global.config.chart.test_mode
      ? "(" +
        rfc.no_count +
        "+" +
        rfc.na_count +
        "+" +
        rfc.not_asked_count +
        ")  / " +
        rfc.question_list.length
      : "";

    rfc.unc_percentage = rfc.unc_count / rfc.question_list.length;
    rfc.unc_percentage_desc = global.config.chart.test_mode
      ? "(" + rfc.unc_count + " of " + rfc.question_list.length + ")"
      : "";

    rfc.not_answered_percentage =
      rfc.not_answered_count / rfc.question_list.length;
    rfc.not_answered_percentage_desc = global.config.chart.test_mode
      ? "(" + rfc.not_answered_count + " of " + rfc.question_list.length + ")"
      : "";

    rfc.answered_percentage = rfc.answered_count / rfc.question_list.length;

    rfc.count_desc =
      "Yes: " +
      rfc.yes_count +
      ", (No: " +
      rfc.no_count +
      ", NotApplicable: " +
      rfc.na_count +
      ", NotAsked: " +
      rfc.not_asked_count +
      ")" +
      ", Unc: " +
      rfc.unc_count +
      " --- " +
      ", NotAnswered: " +
      rfc.not_answered_count +
      ", AnsweredCount: " +
      rfc.answered_count +
      ", asked_count: " +
      rfc.asked_count +
      ", QuestionCount: " +
      rfc.question_list.length;
  });

  return RiverFunctionCategoryWithQuestionArray;
};

// this function is to Combine Answers from LocalStorage to Existing Questions.
export const getRevisedQuestionWithAnswers = (QuestionsListData) => {
  const newQuestionList = [];
  for (let qData of QuestionsListData) {
    const jsonAnswer = localStorage.getItem(
      "AnswerToQuestion_" + qData.question_id
    );

    const newQuestion = qData;

    try {
      if (jsonAnswer) {
        const answerItem = JSON.parse(jsonAnswer);

        if (
          answerItem.is_visible === null ||
          answerItem.is_visible === undefined
        )
          answerItem.is_visible = 999; // 999 = Default to Show

        newQuestion.answer_data = answerItem;
      } else {
        newQuestion.answer_data = {
          question_id: qData.question_id,
          is_visible: 999, // 999 = Default to Show
          choice_id: null,
          choice_comments: null,
          option_choices: null,
          answer_datetime: null,
        };
      }
    } catch (e) {}

    newQuestionList.push(newQuestion);
  }

  return newQuestionList;
};

export const getSurveySectionSummary = (surveySectionArray, answerJson) => {
  surveySectionArray.forEach((surveySection) => {
    let filteredAsked = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        (answerData.answer_data.is_visible === 1 ||
          answerData.answer_data.is_visible === 999 ||
          answerData.answer_data.is_visible === null)
      );
    }, surveySection.question_list);

    let filteredNotAsked = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        answerData.answer_data.is_visible === 0
      );
    }, surveySection.question_list);

    let filteredYes = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        (answerData.answer_data.is_visible === 1 ||
          answerData.answer_data.is_visible === 999 ||
          answerData.answer_data.is_visible === null) &&
        answerData.answer_data.choice_id ===
          global.config.questionnaire.option_choice_id.yes
      );
    }, surveySection.question_list);

    let filteredNo = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        (answerData.answer_data.is_visible === 1 ||
          answerData.answer_data.is_visible === 999 ||
          answerData.answer_data.is_visible === null) &&
        answerData.answer_data.choice_id ===
          global.config.questionnaire.option_choice_id.no
      );
    }, surveySection.question_list);

    let filteredUnc = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        (answerData.answer_data.is_visible === 1 ||
          answerData.answer_data.is_visible === 999 ||
          answerData.answer_data.is_visible === null) &&
        answerData.answer_data.choice_id ===
          global.config.questionnaire.option_choice_id.uncertain
      );
    }, surveySection.question_list);

    let filteredNa = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        answerData.answer_data.is_visible === 1 &&
        answerData.answer_data.choice_id ===
          global.config.questionnaire.option_choice_id.not_applicable
      );
    }, surveySection.question_list);

    let filteredNotAnswered = answerJson.filter(function (answerData) {
      return (
        this.indexOf(answerData.question_id) >= 0 &&
        (answerData.answer_data.is_visible === 1 ||
          answerData.answer_data.is_visible === 999 ||
          answerData.answer_data.is_visible === null) &&
        answerData.answer_data.choice_id === null
      );
    }, surveySection.question_list);

    surveySection.yes_count = filteredYes.length;

    surveySection.no_count = filteredNo.length;
    surveySection.na_count = filteredNa.length;
    surveySection.not_asked_count = filteredNotAsked.length;

    surveySection.unc_count = filteredUnc.length;

    surveySection.not_answered_count = filteredNotAnswered.length;
    surveySection.answered_count =
      filteredYes.length +
      filteredNo.length +
      filteredUnc.length +
      filteredNa.length;
    surveySection.asked_count = filteredAsked.length;

    surveySection.yes_percentage =
      surveySection.yes_count / surveySection.question_list.length;
    surveySection.no_percentage =
      (surveySection.no_count +
        surveySection.na_count +
        surveySection.not_asked_count) /
      surveySection.question_list.length;
    surveySection.unc_percentage =
      surveySection.unc_count / surveySection.question_list.length;
    surveySection.not_answered_percentage =
      surveySection.not_answered_count / surveySection.question_list.length;
    surveySection.answered_percentage =
      surveySection.answered_count / surveySection.asked_count;

    surveySection.not_answered_list = filteredNotAnswered;
  });

  return surveySectionArray;
};

export const getAltClassName = (i) => {
  return i % 2 === 0 ? "alt-row" : "";
};

export const getUserFullName = () => {
  let UserFullName = localStorage.getItem("UserFullName");
  if (UserFullName === null || UserFullName === undefined) {
    const jsonDataString = localStorage.getItem("AnswerToQuestion_10");

    try {
      const ans = JSON.parse(jsonDataString);
      UserFullName = ans.choice_comments;
      localStorage.setItem("UserFullName", UserFullName);
    } catch (e) {
      UserFullName = "Not Available";
    }
  }
  return UserFullName;
};

export const getDateOfCompletion = () => {
  const Prioritization_TimeStamp = new Date(
    localStorage.getItem(
      global.config.prioritization.local_storage_prioritization_timestamp_name
    )
  );
  return Prioritization_TimeStamp.toLocaleDateString();
};

export const hasQueryString = (pageName) => {
  const parsed = queryString.parse(window.location.search);
  pageName = pageName.toLowerCase();

  if (pageName === "" || pageName === "home") {
    return parsed === undefined || parsed.p === null || parsed.p === pageName;
  } else {
    return (
      parsed !== undefined &&
      parsed.p !== undefined &&
      parsed.p !== null &&
      parsed.p.toLowerCase() === pageName
    );
  }
  //return false;
};

export const isThisPage = (pageName) => {
  const url = window.location.href.toLowerCase();

  pageName = "/" + pageName.toLowerCase();

  if (pageName !== "/" && pageName !== "/home") {
    return url.indexOf(pageName) >= 0;
  } else {
    return url === "/";
  }
  //return false;
};

import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ScrollToTop from "react-scroll-to-top";
import styled from "styled-components";

import Footer from "./Footer.js";
import Header from "./Header.js";
import Swoosh from "./Swoosh.js";
import GlobalStyles from "./GlobalStyles.js";

const LayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 88px;

  @media screen and (max-width: ${(props) => props.theme.breakpoint.lg}) {
    padding-top: 104px;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 8000;
  }
`;

export default function Layout({ children }) {
  return (
    <HelmetProvider>
      <LayoutStyled>
        <Helmet>
          <link rel='preconnect' href='https://fonts.googleapis.com' />
          <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
          <link
            href='https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap'
            rel='stylesheet'
          />
        </Helmet>
        <Header />
        <Swoosh />
        {children}
        <Swoosh />
        <Footer />
        <div className='no-print'>
          <ScrollToTop
            smooth='false'
            top='50'
            style={{ right: "100px", zIndex: "99999" }}
            title='scroll to the top'
          />
        </div>
        <GlobalStyles />
      </LayoutStyled>
    </HelmetProvider>
  );
}

import QuestionOptionData from "./Data/QuestionOptionData.json";
import regionLookup from "./Data/RegionLookup.json";
import { useState, useCallback } from "react";
import CheckboxInput from "./CheckboxInput";
import CheckboxInputOther from "./CheckboxInputOther";

const QuestionCheckboxType = ({ questionDataParam, onCheckboxClick }) => {
  const [optionChoices, setOptionChoices] = useState(
    questionDataParam.answer_data &&
      questionDataParam.answer_data.option_choices
      ? questionDataParam.answer_data.option_choices
      : []
  );

  // Fetch the initial question options based on the option group ID
  let questionOptions = QuestionOptionData.filter((option) => {
    return option.option_group_id === questionDataParam.option_group_id;
  });

  // Apply region-based filtering
  const regionAnswer = localStorage.getItem("AnswerToQuestion_40");
  if (
    questionDataParam.question_short_name === "State this project is located" &&
    regionAnswer
  ) {
    const regionNumber = JSON.parse(regionAnswer).choice_id;
    const stateArray = regionLookup[regionNumber];
    questionOptions = questionOptions.filter((option) => {
      return stateArray.includes(option.option_choice_name);
    });
  }

  const handleCheckboxClick = useCallback(
    (option_choice_id, otherUserInput, isChecked, option_choice_name) => {
      if (!isChecked) {
        const removedChoices = optionChoices.filter(
          (option) => option.option_choice_id !== option_choice_id
        );
        if (onCheckboxClick !== undefined) {
          onCheckboxClick(removedChoices);
        }
        setOptionChoices(removedChoices);
        return;
      }

      let itemExists = false;
      let newList = optionChoices.map((item) => {
        if (item.option_choice_id === option_choice_id) {
          const updatedItem = {
            ...item,
            comments: otherUserInput,
            is_checked: isChecked,
          };
          itemExists = true;
          return updatedItem;
        }
        return item;
      });

      if (!itemExists && isChecked) {
        const item = {
          question_id: questionDataParam.question_id,
          option_choice_id: option_choice_id,
          comments: otherUserInput,
          is_checked: isChecked,
          option_choice_name: option_choice_name,
        };

        let newList1 = [...optionChoices, item];
        newList1 = SortOptions(newList1);

        if (onCheckboxClick !== undefined) {
          onCheckboxClick(newList1);
        }

        setOptionChoices(newList1);
      } else if (isChecked) {
        if (onCheckboxClick !== undefined) {
          onCheckboxClick(newList);
        }
        newList = SortOptions(newList);
        setOptionChoices(newList);
      } else {
        let newList2 = optionChoices.filter(
          (item) => item.option_choice_id !== option_choice_id
        );
        newList2 = SortOptions(newList2);
        if (onCheckboxClick !== undefined) {
          onCheckboxClick(newList2);
        }
        setOptionChoices(newList2);
      }
    },
    [questionDataParam, optionChoices, onCheckboxClick]
  );

  return (
    <>
      {questionOptions.map((option) => {
        let isOtherPleaseSpecify = option.is_addition_input_ind === 1;

        let userSelectedOptionChoice = GetUserSelectedOptionChoice(
          optionChoices,
          option.option_choice_id
        );

        return (
          <div key={option.option_choice_id} className="input-group">
            {isOtherPleaseSpecify ? (
              <CheckboxInputOther
                questionDataParam={questionDataParam}
                checkboxOption={option}
                userSelectionIsCheck={
                  userSelectedOptionChoice
                    ? userSelectedOptionChoice.is_checked
                    : false
                }
                userEnteredComments={
                  userSelectedOptionChoice
                    ? userSelectedOptionChoice.comments
                    : null
                }
                onCheckboxClick={handleCheckboxClick}
              />
            ) : (
              <CheckboxInput
                questionDataParam={questionDataParam}
                checkboxOption={option}
                userSelectionIsCheck={
                  userSelectedOptionChoice
                    ? userSelectedOptionChoice.is_checked
                    : false
                }
                onCheckboxClick={handleCheckboxClick}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

const GetUserSelectedOptionChoice = (optionChoices, option_choice_id) => {
  if (optionChoices === null || optionChoices.length === 0) return null;

  let userSelectedOption = optionChoices.filter(
    (item) => item.option_choice_id === option_choice_id
  );

  if (userSelectedOption.length === 1) {
    return userSelectedOption[0];
  }

  return null;
};

const SortOptions = (answerOptions) => {
  answerOptions.sort(function (a, b) {
    return a.option_choice_id - b.option_choice_id;
  });
  return answerOptions;
};

export default QuestionCheckboxType;

import { useState, useEffect } from "react";

const QuestionTextboxType = ({ questionDataParam, onTextboxInputChange }) => {
  const [answerTxt, setAnswerTxt] = useState(
    questionDataParam.answer_data.choice_comments
  );

  useEffect(() => {
    // use this like component did mount
  });

  const handleTextboxBlur = () => {
    if (answerTxt !== null) { 
      onTextboxInputChange(answerTxt); 
    }
  };
  // const handleTextboxBlur = useCallback(() => {
  //     onTextboxInputChange(answerTxt);
  // }, [questionDataParam, onTextboxInputChange])

  return (
    <div className="input-group">
      <input
        type="textbox"
        className="input-control"
        id={"textbox_" + questionDataParam.question_id}
        value={answerTxt ? answerTxt : " "}
        onBlur={() => {
          handleTextboxBlur();
        }}
        onChange={(e) => {
          setAnswerTxt(e.target.value);
        }}
      ></input>
    </div>
  );
};

export default QuestionTextboxType;

import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

    * {
        margin: 0;
        padding: 0;
        font-family: 'Roboto-Regular', sans-serif;
        color: #000000;
    }

    .copy {
        align-self: flex-start;
        margin-bottom: 1.5rem;
        font-size: 1.125rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-smooth: never;

        a {
            font-family: ${(props) => props.theme.font[0]}, sans-serif;
            font-weight: 500;
            color: ${(props) => props.theme.color.primaryLink};
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }
    }

`;

export default GlobalStyles;

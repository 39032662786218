import React from "react";

const BarChartPublicationSource = () => {
  return (
    <div style={{ fontSize: "0.8rem" }}>
      <a
        href="https://lowimpacthydro.org/wp-content/uploads/2020/07/A_Checklist_of_River_Function_Indicators_for_hydropower_ecological_assessment-2019.pdf "
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "underline" }}
        title="Science of the Total Environment"
      >
        {" "}
        Source:
      </a>{" "}
      Tables SI and Table S2 of Pracheil BM, McManamay RA, Parish ES, Curd SL,
      Smith BT, DeRolph CR, Witt AM, Ames S, Day MB, Graf W, Infante D, McCoskey
      D, Rugani K, Vezina C, Welch T, West A (2019) A Checklist of River
      Function Indicators for hydropower ecological assessment. Science of the
      Total Environment 687: 1245-1260.
    </div>
  );
};

export default BarChartPublicationSource;

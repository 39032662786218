import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setActivePage } from "./slices/navigationSlice.js";

import MainContentsQuestionnaireV1 from "./components/Impacts/MainContentsQuestionnaireV1.js";
import RiverFunctionChart from "./components/Impacts/RiverFunctionChart.js";
import UserGuide from "./components/UserGuide";
import Help from "./components/Help";

import IntroductionSummary from "./components/IntroductionSummary.js";
import Prioritization from "./components/Prioritizations/Prioritization.js";
import FinalReportSummary from "./components/FinalReportSummary/FinalReportSummary.js";
import Next from "./components/Next/Next.js";
import Mitigations from "./components/Next/Mitigations.js";
import Studies from "./components/Next/Studies.js";
import Regulations from "./components/Next/Regulations.js";
import PageNotFound from "./components/PageNotFound";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FinalReportDetail from "./components/FinalReportSummary/FinalReportDetail";
import "./App.css";

import Theme from "./components/Theme.js";
import Layout from "./components/Layout.js";
import Impacts from "./components/Impacts/Impacts.js";
import Instructions from "./components/Impacts/Instructions.js";

import ScrollToTop from "./components/ScrollToTop.js";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActivePage(window.location.pathname));
  }, [dispatch]);
  return (
    <Theme>
      <Router>
        <Layout>
          <Routes>
            <Route path='/' exact={true} element={<IntroductionSummary />} />
            <Route path='/Impacts' exact={true} element={<Impacts />} />
            <Route path='/Impacts/Instructions' element={<Instructions />} />
            <Route
              path='/Impacts/Questionnaire'
              exact={true}
              element={<MainContentsQuestionnaireV1 />}
            />
            <Route path='/Impacts/Results' element={<RiverFunctionChart />} />
            <Route path='/Prioritization' element={<Prioritization />} />
            <Route
              path='/FinalReportSummary'
              element={<FinalReportSummary />}
            />
            <Route path='/FinalReportDetail' element={<FinalReportDetail />} />
            <Route path='/Next' exact={true} element={<Next />} />
            <Route path='/Next/Mitigations' element={<Mitigations />} />
            <Route path='/Next/Studies' element={<Studies />} />
            <Route path='/Next/Regulations' element={<Regulations />} />
            <Route path='/UserGuide' exact={true} element={<UserGuide />} />
            <Route path='/Help' exact={true} element={<Help />} />
            <Route element={<PageNotFound />} />
          </Routes>
          <ScrollToTop />
        </Layout>
      </Router>
    </Theme>
  );
};

export default App;

import { useState } from "react";
import styled from "styled-components";
import { GrPrint } from "react-icons/gr";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { GrDocumentPdf } from "react-icons/gr";
import Modal from "react-modal";

const PrimaryButtonStyled = styled.button`
  align-self: center;
  padding: 4px 16px;
  height: 34px;
  border: 2px solid ${(props) => props.theme.color.primary};
  border-radius: 8px;
  background-color: #fff;
  color: ${(props) => props.theme.color.primary};
  text-align: center;

  &:hover {
    background-color: ${(props) => props.theme.color.primary};
    color: #fff;
  }
`;

const PrintOption = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const printThisPage = () => {
    window.print();
  };

  function closeModal() {
    setIsModalOpen(false);
  }

  const customStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(255, 255, 255, 0.75)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "50%",
      height: "78%",
      borderRadius: "4px",
      border: "1px solid black",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <>
      <div className='no-print' style={{ marginTop: "10px" }}>
        <button
          type='button'
          className='btn btn-outline-secondary btn-sm'
          style={{ width: "130px" }}
          onClick={() => printThisPage()}
          title='Print Page'
        >
          <GrPrint /> Print Page{" "}
        </button>
        <IoIosHelpCircleOutline
          style={{ cursor: "pointer", marginLeft: "10px" }}
          onClick={() => setIsModalOpen(true)}
          title='Print Help'
        />
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        onClick={() => setIsModalOpen(false)}
        contentLabel='Print Option'
      >
        <div className='container'>
          <div
            style={{
              width: "100%",
              backgroundColor: "#177AC8",
              color: "white",
              padding: "10px",
              marginBottom: "20px",
            }}
          >
            Print Option Help
          </div>
          <div className='row' style={{ fontSize: "0.8rem" }}>
            <div className='col'>
              <p>
                <GrDocumentPdf style={{ fontSize: "3.0rem", color: "red" }} />
              </p>
              <p>
                You can print this page in PDF format. Depending on your
                browser, you can choose "
                <span
                  style={{
                    border: "2px solid rgb(0, 162, 232)",
                    padding: "1px",
                  }}
                >
                  Save as PDF
                </span>
                " in the 'Destination' or 'Printer'.{" "}
              </p>

              <p>
                You will need to check the box to include "
                <span
                  style={{
                    border: "2px solid rgb(181, 230, 29)",
                    padding: "1px",
                  }}
                >
                  Background graphics
                </span>
                " before you print.
              </p>

              <p>
                Please note that the print options are different depending on
                your browser. The image on the right side is based on Chrome.
                Your options may be slightly different.
              </p>
            </div>
            <div className='col'>
              <img
                src='/images/full_print_option_chrome.JPG'
                title='printer destination.'
                alt='printer destination.'
              />
              <br></br>
              <small>Options in Chrome</small>
            </div>
          </div>
          <hr />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <PrimaryButtonStyled
              type='button'
              onClick={() => setIsModalOpen(false)}
              title='Close the dialog box'
            >
              Close
            </PrimaryButtonStyled>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PrintOption;

import React from "react";
import "../../config";

const RiverFunctionNotes = ({ answerJson, riverFunction }) => {
  let questionsUnderThisRiverFunction = answerJson.filter(function (
    answerData
  ) {
    return (
      this.indexOf(answerData.question_id) >= 0 &&
      answerData.answer_data.is_visible === 1 &&
      answerData.has_dependent_ind === 1 &&
      answerData.input_type_name === "radio"
    );
  },
  riverFunction.question_list);

  let questionIdWithNoteArray = [];
  questionsUnderThisRiverFunction.forEach((question) => {
    const dependentNoteQuestions = answerJson.filter(function (answerData) {
      return (
        answerData.input_type_name === "textarea" &&
        question.question_id === answerData.parent_id
      );
    });
    if (dependentNoteQuestions.length > 0)
      questionIdWithNoteArray.push(dependentNoteQuestions[0].question_id);
  });

  let validQuestionWithNotes = answerJson.filter(function (answerData) {
    return (
      this.indexOf(answerData.question_id) >= 0 &&
      answerData.answer_data.choice_comments !== null &&
      answerData.answer_data.choice_comments !== ""
    );
  }, questionIdWithNoteArray);

  return (
    validQuestionWithNotes.length > 0 && (
      <div style={{ marginLeft: "30px", marginBottom: "20px" }}>
        <ul>
          {validQuestionWithNotes.map((questionWithNote, index) => (
            <li key={index} style={{ fontSize: "0.7rem" }}>
              Q{questionWithNote.question_display_id} -{" "}
              {questionWithNote.answer_data.choice_comments}
            </li>
          ))}
        </ul>
      </div>
    )
  );
};

export default RiverFunctionNotes;

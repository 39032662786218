import React from "react";
import QuestionsListData from "../Data/QuestionsData.json";
import { getRevisedQuestionWithAnswers } from "../Utilities/Util.js";
import QuestionOptionData from "../Data/QuestionOptionData.json";
// import {getAltClassName} from '../Utilities/Util.js'

const FinalReportBackgroundInfo = () => {
  const answerJson = getRevisedQuestionWithAnswers(QuestionsListData);
  const backgroundAnswer = answerJson.filter((a) => {
    return a.section_group_txt === "B";
  });

  const getAnswerInText = (questionAnswerData) => {
    let answerTxt = "";

    if (questionAnswerData.input_type_name === "text") {
      answerTxt = questionAnswerData.answer_data.choice_comments;
    } else if (questionAnswerData.input_type_name === "checkbox") {
      if (
        questionAnswerData.answer_data !== null &&
        questionAnswerData.answer_data.option_choices !== null
      )
        for (
          let i = 0;
          i < questionAnswerData.answer_data.option_choices.length;
          i++
        ) {
          answerTxt +=
            "[" +
            questionAnswerData.answer_data.option_choices[i]
              .option_choice_name +
            "], ";
        }

      answerTxt = answerTxt.substring(0, answerTxt.length - 2);
    } else if (questionAnswerData.input_type_name === "radio") {
      if (questionAnswerData.answer_data !== null) {
        const choiceId = questionAnswerData.answer_data.choice_id;
        let x = QuestionOptionData.filter((option) => {
          return option.option_choice_id === choiceId;
        });
        if (x.length > 0) answerTxt = x[0].option_choice_name;

        answerTxt = answerTxt.replace(
          "(please specify)",
          "(" + questionAnswerData.answer_data.choice_comments + ")"
        );
      }
    }
    return answerTxt;
  };

  return (
    <div style={{ marginBottom: "40px" }}>
      <h3 className="section-heading">Background Information</h3>
      <table style={{ fontSize: "0.8rem" }}>
        <tbody>
          {backgroundAnswer.map((q, index) => (
            <tr key={index}>
              <td
                style={{
                  verticalAlign: "top",
                  padding: "5px",
                  fontWeight: "bold",
                }}
              >
                <span htmlFor={"Q" + q.question_id + "-" + index}>
                  {q.question_short_name}
                </span>
                :
              </td>
              <td id={"Q" + q.question_id + "-" + index}>
                {getAnswerInText(q)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FinalReportBackgroundInfo;

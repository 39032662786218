import React from "react";
import styled from "styled-components";
import NavItem from "./NavItem";

const NavStyled = styled.nav`
  display: flex;
  align-items: center;
  height: 48px;

  @media screen and (max-width: ${(props) => props.theme.breakpoint.lg}) {
    height: 36px;
  }

  .nav {
    display: flex;
    justify-content: space-around;
    height: 48px;

    @media screen and (max-width: ${(props) => props.theme.breakpoint.lg}) {
      height: 36px;
    }

    .nav__item,
    span {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 0.375rem;
      padding-top: 0.375rem;
      height: 100%;

      @media screen and (max-width: ${(props) => props.theme.breakpoint.md}) {
        margin: 0 0.125rem;
      }

      .chevron {
        display: none;
      }

      a {
        color: ${(props) => props.theme.color.body};
        font: 500 0.875rem ${(props) => props.theme.font[0]}, sans-serif;
        text-decoration: none;

        &:hover {
          color: ${(props) => props.theme.color.primary};
        }
      }

      &.active {
        padding-top: 0px;

        .chevron {
          display: block;
          margin-bottom: 2px;
        }

        a {
          color: ${(props) => props.theme.color.body};
          font: 500 0.875rem ${(props) => props.theme.font[0]}, sans-serif;

          &:hover {
            color: ${(props) => props.theme.color.body};
          }
        }
      }
    }
  }
`;

export default function Nav() {
  const navItems = [
    {
      to: "/",
      text: "Introduction",
      hasSubMenu: false,
      hasSubNav: false,
      subNav: null,
    },
    {
      to: "/Impacts",
      text: "Impacts",
      hasSubMenu: true,
      hasSubNav: true,
      subNav: [
        {
          to: "/Impacts/Instructions",
          text: "Instructions",
        },
        {
          to: "/Impacts/Questionnaire",
          text: "Questionnaire",
        },
        { to: "/Impacts/Results", text: "Results" },
      ],
    },
    {
      to: "/Prioritization",
      text: "Prioritization",
      hasSubNav: false,
      subNav: null,
    },
    {
      to: "/FinalReportDetail",
      text: "Report",
      hasSubNav: false,
      subNav: null,
    },
    {
      to: "/Next",
      text: "What's Next?",
      hasSubNav: true,
      subNav: [
        { to: "/Next/Studies", text: "Studies" },
        { to: "/Next/Mitigations", text: "Mitigations" },
        { to: "/Next/Regulations", text: "Regulations" },
      ],
    },
    { to: "/Help", text: "Help", hasSubNav: false, subNav: null },
  ];
  return (
    <NavStyled>
      <ul className='nav'>
        {navItems.map(({ hasSubNav, subNav, to, text }, index) => (
          <NavItem
            hasSubNav={hasSubNav}
            index={index}
            key={index}
            navItemsLength={navItems.length}
            subNav={subNav}
            to={to}
            text={text}
          />
        ))}
      </ul>
    </NavStyled>
  );
}

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { setActivePage } from "../../slices/navigationSlice";

import Chevron from "../SVGs/Chevron";
import SubNav from "./SubNav";

const NavItemStyled = styled.li`
  display: flex;

  span,
  .direction {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 0.375rem;
    padding-top: 0.375rem;
    height: 100%;

    @media screen and (max-width: ${(props) => props.theme.breakpoint.md}) {
      margin: 0 0.125rem;
    }

    .chevron {
      display: none;
    }

    a {
      color: ${(props) => props.theme.color.body};
      font: 500 0.875rem ${(props) => props.theme.font[0]}, sans-serif;
      text-decoration: none;

      &:hover {
        color: ${(props) => props.theme.color.primary};
      }
    }

    &.active {
      padding-top: 0px;

      .chevron {
        display: block;
        margin-bottom: 2px;
      }

      a {
        color: ${(props) => props.theme.color.body};
        font: 500 0.875rem ${(props) => props.theme.font[0]}, sans-serif;

        &:hover {
          color: ${(props) => props.theme.color.body};
        }
      }
    }
  }

  .direction {
    margin: 0 0.125rem;
    color: ${(props) => props.theme.color.primary};
  }
`;

export default function NavItem(props) {
  const { hasSubNav, index, navItemsLength, subNav, to, text } = props;
  const [showSubNav, setShowSubNav] = useState(false);
  const selectedPage = useSelector((state) => state.navigation.activePage);
  const dispatch = useDispatch();

  return (
    <NavItemStyled>
      <span
        key={text}
        /* This monstrosity takes into account the sub-nav for What's Next */
        /* Should definitely come up with a better way of doing this */
        className={
          selectedPage && selectedPage !== "" && selectedPage === to
            ? "nav__item active"
            : "nav__item"
        }
        onMouseEnter={hasSubNav ? () => setShowSubNav(true) : null}
        onMouseLeave={hasSubNav ? () => setShowSubNav(false) : null}
      >
        <Chevron />
        <NavLink
          to={to}
          title={text}
          onClick={() => dispatch(setActivePage(to))}
        >
          {text}
        </NavLink>
        {hasSubNav && showSubNav ? <SubNav navItems={subNav} /> : null}
      </span>
      {/* using navItemsLength - 1 to remove the '>' before 'Help' */}
      {index + 1 < navItemsLength - 1 ? (
        <p className='direction'>{`>`}</p>
      ) : null}
    </NavItemStyled>
  );
}

import React from "react";
import styled from "styled-components";
import PageTitle from "../PageTitle";
import InPageNav from "../InPageNav";
import navItems from "./navItems";

import SequentialPageNav from "../SequentialPageNav/SequentialPageNav.js";

const ImpactsStyled = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;

  @media screen and (max-width: ${(props) => props.theme.breakpoint.lg}) {
    padding: 0 2rem;
  }

  .figure {
    margin-bottom: 5rem;
    width: 100%;
    height: auto;
  }
`;

export default function Impacts() {
  return (
    <ImpactsStyled>
      <InPageNav navItems={navItems} />
      <PageTitle title='Identify Potential Environmental Impacts' />
      <p className='copy'>
        This module was designed to provide interested parties with a
        systematic, transparent, and scientifically based method of identifying
        potential environmental impacts from a proposed hydropower project.{" "}
      </p>
      <p className='copy'>
        Please follow the Instructions to fill out the River Function Indicator
        (RFI) Questionnaire. The Questionnaire will identify key functions of
        the riverine ecosystem that may be affected by the hydropower project's
        construction and/or operations. Be sure to review your Results and make
        any necessary changes/corrections before moving on to the Prioritization
        module.
      </p>
      <p className='copy'>
        The suggested process for using the Questionnaire Results is summarized
        below:
      </p>
      <img
        src='/images/rfi-questionnaire-process.svg'
        className='figure'
        alt='introduction figure 1'
      />
      <SequentialPageNav prev='/' next='/Impacts/Instructions' />
    </ImpactsStyled>
  );
}
